export async function ElementOverContainer(stageRef, elements, item){
    // get containers
    const containers = elements.filter(i => i.type == 'container');

    // all layers at position
    const inter = await stageRef.getAllIntersections(stageRef.getPointerPosition());

    // is one of the layers a container at position
    const findContainerAtPos = containers.filter(o => inter.some((j) => (o.id === j.attrs.id && j.attrs.id !== item.id)));

    // Has the container already got the element as a child
    // const isElementAlreadyAChild = await findContainerAtPos.map((i)=>{
    //     return i.children.some((j) => j.id == item.id);
    // })
    if(findContainerAtPos.length !== 0){
        //there is a container at element position
        return findContainerAtPos[0];
    }else{
        return false;
    }    
}