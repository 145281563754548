import React, { useEffect, useState } from 'react';
import './stylepanel.css';
import Fillbordercomponent from './optioncomponents/fillbordercomponent/fillbordercomponent';
import BorderRadiusComponent from './optioncomponents/borderradiuscomponent/borderradiuscomponent';
import '../elementpanels/elementpanel.css'

function StylePanel ({item}){

    return(
       <div className='stylepanelcontainer element-panel-container'>
        <div className="element-panel-title">
            <p>Styles</p>
        </div>
        {item.type !== 'image' && item.type !== 'switch' ?
            <Fillbordercomponent item={item}/>
            :
            null
        }
            <BorderRadiusComponent item={item}/>
       </div>
    );
}

export default StylePanel;