import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from '../../../supabase/supabaseclient';
import { useAuthContext } from '../../../context/auth/authcontext';
import './login.css';

function Login(){

    const navigate = useNavigate();
    const authContext = useAuthContext();
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorState, setErrorState] = useState('');


    const handleLogin = () => {
        setErrorState('');
        if(!email || !password){
            setErrorState('Please fill out all fields');
            return;
        }

       const {data, error} = supabase.auth.signInWithPassword({
            email: email,
            password: password,
        }).then((data)=>{
            if(data){
                // once user has logged up
                // navigate to dashboard
               // authContext.setUserSession(data.session);
                navigate('/dashboard');
            }
            //console.log('login data output', data);
        });

        if(error){
            console.log(error);
            setErrorState('Something is wrong. Check your email and password');
        }
    }

    return (
        <div className='login-main'>
            <div className='login-header-container'>
                <h1 className="login-header">Rivveo <span>BETA</span></h1>
            </div>
            <div className='login-box'>
                <h1 className='login-box-header'>Login to your account</h1>
                <div className='login-box-input'>
                    <label>Email Address</label>
                    <input
                        className="inputField"
                        type="email"
                        placeholder="Your email"
                        value={email}
                        required={true}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                    
                <div className='login-box-input'>
                    <label>Password</label>
                    <input
                        className="inputField"
                        type="password"
                        placeholder="Your password"
                        value={password}
                        required={true}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <p className='signup-error'>{errorState}</p>
                <div>
                    <button className='login-box-button' disabled={loading} onClick={()=>handleLogin()}>
                    {loading ? <span>Loading</span> : <span>Login</span>}
                    </button>
                </div>
                <h5 className='login-box-or'>or</h5>
                <div className='login-box-create-account-container'>
                    <Link className='login-box-create-account' to={'/signup'}>Create an account</Link>
                </div>
            </div>
        </div>
    )

}

export default Login;