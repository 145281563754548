
export default function executeGlobalAnim (anim, element, translatedX, animTrigger) {
    //console.log(anim)
   switch (anim) {
    case 'TransitionInLeftRight':
        return translatedX + element.xpos;
    case 'TransitionOutLeftRight':
        return animTrigger.endValue
    default:
        break;
   }
}