import React, { useState, useEffect, useRef, Fragment } from 'react';
import { supabase } from '../../../supabase/supabaseclient';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthContext } from '../../../context/auth/authcontext';
import './signup.css'

function Signup(){

    const navigate = useNavigate();
    const authContext = useAuthContext();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorState, setErrorState] = useState('');

    async function signUpNewUser () {

        if(!email || !firstName || !lastName || !password){
            setErrorState('Please fill out all fields');
            return;
        }
        await supabase.auth.signUp({
          email: email,
          password: password,
        }).then((data)=>{
            // console.log(data);
            // console.log(data.data.user.id)
            if(!data.data.user){
                setErrorState(data.error.message);
                return;
            }
            supabase
            .from('Users')
            .insert({
                auth_id: data.data.user.id,
                first_name: firstName,
                last_name: lastName,
                email: email
            })
            .select()
            .then((data)=>{
                // save user us
                console.log(data.data[0]);
                authContext.setUserFn(data.data[0]);
                navigate('/signup/select-plan');
            })
        });
    }

    return (
        <div className='signup-main'>
            <div className='signup-header-container'>
                <h1 className="signup-header">Rivveo <span>BETA</span></h1>
            </div>
            <div className='signup-box'>
                
                <div className='signup-header-container'>
                    <h1 className='signup-box-header'>Create account</h1>
                    <div className='signup-progress'>
                        <p>1/5</p>
                    </div>
                </div>

                <div className='signup-box-input'>
                    <label>First name</label>
                    <input type="text" id="firstname" value={firstName} placeholder="Your First Name" onChange={(e) => setFirstName(e.target.value)}/>
                </div>
                <div className='signup-box-input'>
                    <label>Last name</label>
                    <input type="text" id="lastname" value={lastName} placeholder="Your Last Name" onChange={(e) => setLastName(e.target.value)}/>
                </div>
                <div className='signup-box-input'>
                    <label>Email</label>
                    <input type="email" id="email" value={email} placeholder="Your Email" onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className='signup-box-input'>
                    <label>Password</label>
                    <input type="password" id="password" value={password} placeholder="Your Password" onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <p className='signup-error'>{errorState}</p>
                <button className='signup-box-button' onClick={()=>signUpNewUser()}>
                    Sign Up
                </button>
                <h5 className='signup-box-or'>or</h5>
                <div className='signup-box-create-account-container'>
                    <Link className='signup-box-create-account' to={'/login'}>Login</Link>
                </div>
            </div>
            
        </div>

    )

}

export default Signup;