import React, { useEffect, useState } from "react"
import'./textfieldpanel.css';
import * as Popover from '@radix-ui/react-popover';
import { useEditorContext } from "../../../../../../context/editor/editorcontext";
import FontPicker from "../../fontpicker/fontpicker";
import FontSize from "../../fontpicker/fontsize/fontsize";
import { HexColorPicker, HexColorInput } from "react-colorful";
import '../elementpanel.css';

function TextFieldPanel ({item}){

    const editorContext = useEditorContext();
    const [placeholder, setPlaceholder] = useState(item.placeholder);
    const [value, setValue] = useState(item.value);
    const [font, setFont] = useState(item.font);
    const [fontSize, setFontSize] = useState(item.font_size);
    const [fontColour, setFontColour] = useState(item.font_colour);

    useEffect(()=>{
     //console.log('item is updating');
     setPlaceholder(item.placeholder);
     setValue(item.value);
     setFont(item.font);
     setFontSize(item.font_size);
     setFontColour(item.font_colour);
    },[item])

    const updateElement = (placeholder, value, font, fontSize, fontColour) => { 
        if(item?.id == editorContext.activeElement?.id){
            const props = { 
                type: item.type,
                id: item.id,
                grouped: item.grouped,
                grouped_by: item.grouped_by,
                placeholder: placeholder,
                value: value,
                font: font,
                font_size: fontSize,
                font_colour: fontColour,
            }
            editorContext.updateElementCustomPanel(props);
        }
    }

    const handlePanel = (placeholder, value, font, fontSize, fontColour) => {
        setPlaceholder(placeholder);
        setValue(value);
        setFont(font);
        setFontSize(fontSize);
        setFontColour(fontColour);
        updateElement(placeholder, value, font, fontSize, fontColour);
    }

    return(
        <div className="textfieldpanelcontainer element-panel-container">
            <div className="element-panel-title">
                <p>Text Field</p>
            </div>
            <div className="element-panel-section">
                <div className="element-panel-input">
                    <p>Placeholder</p>
                    <input value={placeholder} onChange={(e)=>handlePanel(e.target.value, value, font, fontSize, fontColour)}/>
                </div>
                <div className="element-panel-input">
                    <p>Value</p>
                    <input 
                    value={value} 
                    onChange={(e)=>{
                        if(!e.target.value){
                            handlePanel(placeholder, " ", font, fontSize, fontColour)
                        }else{
                            handlePanel(placeholder, e.target.value, font, fontSize, fontColour)
                        }
                    }}/>
                </div>
            </div>
            <div className="element-panel-section">
                <div>
                    <FontPicker item={item} setFont={setFont} onChange={(e)=>handlePanel(placeholder, value, e, fontSize, fontColour)}/>
                </div>
                <div>
                    <FontSize item={item} setFontSize={setFontSize} onChange={(e)=>handlePanel(placeholder, value, font, e, fontColour)}/>
                </div>
            </div>
            
            <div>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <div className="element-panel-colour">
                            <p>Font colour</p>
                            <div>
                                <div style={{background: fontColour}}/>
                                <p>{fontColour}</p>
                            </div>
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                    <Popover.Content sideOffset={10}>
                        <div className="element-panel-colour-picker">
                            <HexColorPicker color={fontColour} onChange={(e)=>handlePanel(placeholder, value, font, fontSize, e)}/>
                            <HexColorInput color={fontColour} onChange={(e)=>handlePanel(placeholder, value, font, fontSize, e)} />
                        </div>
                    </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
        </div>
    )
}

export default TextFieldPanel