import React, {useEffect, useState} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import '../../dropdown.css';
import './trigger.css';
import '../../prototypemenu.css';
import { useEditorContext } from '../../../../../context/editor/editorcontext';
import { textfieldProtoOptions, buttonProtoOptions, textProtoOptions, switchProtoOptions, imageProtoOptions, containerProtoOptions } from '../../../../../context/editor/prototypeoptions/prototypeoptions';


function Trigger ({interaction, passdata, navigateTo, setTrigger}){

    const editorContext = useEditorContext();
    const [trig, setTrig] = useState(editorContext.activeElement.proto_trigger ? editorContext.activeElement.proto_trigger :  'Select Trigger');

    const renderOptions = (type) => {
        switch (type) {
            case 'textfield':
                return textfieldProtoOptions.trigger;
                break;
            case 'button':
                return buttonProtoOptions.trigger;
                break;
            case 'text':
                return textProtoOptions.trigger;
                break;
            case 'switch':
                return switchProtoOptions.trigger;
                break;
            case 'image':
                return imageProtoOptions.trigger;
                break;
            case 'container':
                return containerProtoOptions.trigger;
            default:
                break;
        }
    }

    useEffect(()=>{
       setTrigger(editorContext.activeElement.proto_trigger);
    },[editorContext.activeElement])

    const handle = (trigger) => {
        setTrig(trigger);
        setTrigger(trigger);
        const props = {
            id: editorContext.activeElement.id,
            prototyping: editorContext.activeElement.prototyping,
            type: editorContext.activeElement.type,
            trigger: trigger,
            interaction: interaction,
            navigateTo: navigateTo,
        }
        editorContext.updateElementProto(props);
    }

    return(
        <div className='triggerContainer'>
           <div>
                <h3 className='prototype-menu-title'>Trigger</h3>
           </div>
            <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button className="prototype-menu-dropdown-trigger" aria-label="Customise options">
                    {trig}
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="prototype-menu-dropdown-content">
                    {
                        renderOptions(editorContext.activeElement.type).map((e) => (
                            <DropdownMenu.Item className="prototype-menu-dropdown-item" onClick={()=>handle(`${e}`)}>
                                {e}
                            </DropdownMenu.Item>
                        ))
                    }                
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </div>
        
    )
}

export default Trigger;