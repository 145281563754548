import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group } from 'react-konva';
import { usePrototypeContext } from '../../context/prototype/prototypecontext';
import PlayerTextField from './elements/textfield/playertextfield'; 
import PlayerButton from './elements/button/playerbutton';
import PlayerText from './elements/text/playertext';
import PlayerSwitch from './elements/switch/playerswitch';
import PlayerImage from './elements/image/playerimage';
import PlayerContainer from './elements/container/playercontainer';
import { RepositionElements } from './utils/repositionElements';
import { Spring, animated, useSpring } from '@react-spring/konva';
import PlayerHeader from './header/playerheader';


function PlayerCanvas ({setScreenX, setScreenY, setScreenScale,  setClipScreenWidth, setClipScreenHeight }) {

    // render all elements
    // render home screen first (screen[0] for meantime)
    // render home screen and element on canvas

    const prototypeContext = usePrototypeContext();
    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;
    const stageRef = useRef(null);
    const [screenWidth, setScreenWidth] = useState(null);
    const [screenHeight, setScreenHeight] = useState(null);
    const [scale, setScale] = useState(0.8);
    const [readyToRender, setReadyToRender] = useState(false);
    const [CalcTranslation, setCalcTranslation] = useState(false);

    const [translatedX, setTranslatedX] = useState(null);
    const [translatedY, setTranslatedY] = useState(null);
    const bufferX = screenWidth + 50;

    const newCanvasWidth = canvasWidth * (10 / (scale * 10)) -20;
    const newCanvasHeight = canvasHeight * (10 / (scale * 10)) -20;
    const x = newCanvasWidth/2;
    const y = newCanvasHeight/2;

    useEffect(()=>{
          console.log('about to load prototype', screenWidth, prototypeContext.screens)
        if(CalcTranslation && prototypeContext.screens){
            console.log('screen width', screenWidth)
            if(screenWidth > canvasWidth){
                console.log('screen is wider than tall');
                const newdimsW = screenWidth - (screenWidth - canvasWidth) - 150;
                setScale(newdimsW / screenWidth);
                setScreenScale(newdimsW / screenWidth);
            }else{
                console.log(' screen is taller than wide');
                const newdimsH = screenHeight - (screenHeight - canvasHeight) - 160;
                setScale(newdimsH / screenHeight);
                setScreenScale(newdimsH / screenHeight);
            }
            


            // if(screenHeight > canvasHeight){
            //     //the screen is taller than canvas
            //     console.log('screen is taller than canvas');
            //     const newdimsH = screenHeight - (screenHeight - canvasHeight);
            //     setScale(newdimsH / screenHeight);
                 
            // }else if(screenWidth > canvasWidth){
            //     //the screen is wider than canvas
            //     console.log('screen is wider than canvas');
                
            // }

            //console.log(designScreen[0]);
            //console.log('x', x);
            //console.log('screenwidth', screenWidth)
            // const screen1 = {xpos: designScreen[0].xpos, ypos: designScreen[0].ypos} 
            // const screen2 = {xpos: x - screenWidth/2, ypos: y - screenHeight/2}
            // const xy = RepositionElements(screen1, screen2);
            //console.log('this is xy', xy);
            //setTranslatedX(xy.xpos);
            //setTranslatedY(xy.ypos);
            //console.log('finished calc');
            setReadyToRender(true);
        }    
    },[prototypeContext.screens, CalcTranslation])

    useEffect(()=>{
        if(prototypeContext.file){
            // get screen size
            const fileScreenSize = prototypeContext?.file?.screen_size;
            const screenSize = prototypeContext.getScreenSize(fileScreenSize);
            setScreenWidth(screenSize.width);
            setScreenHeight(screenSize.height);
            setClipScreenWidth(screenSize.width);
            setClipScreenHeight(screenSize.height);
            setCalcTranslation(true);

            //setting for parent to calculate clipping mask
            //console.log('setting screenY', y - screenHeight/2)
            setScreenX((x  - (screenWidth/2)) * scale);
            setScreenY((y  - (screenHeight/2)) * scale);
        }
    },[prototypeContext.file])

    return(
            <Stage 
                name='playercanvas'
                ref={stageRef}
                width={canvasWidth} 
                height={canvasHeight} 
                perfectDrawEnabled={false}
                x={0}
                y={0}
                scaleX={scale}
                scaleY={scale}
                style={{zIndex: 1}}
            >   
                <Layer x={0} y={0}>
                    {/* <Rect x={x} y={y} width={50} height={50} fill={'#000'} /> */}
                    <Rect x={x} y={y} offsetX={screenWidth/2} offsetY={screenHeight/2} width={screenWidth} height={screenHeight} fill={'#fff'}/>
                    {   readyToRender &&
                        prototypeContext.element.toReversed().map((item) => {
                            switch (item.type) {
                                case 'textfield':
                                    return <PlayerTextField element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'button':
                                    return <PlayerButton element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'text':
                                    return <PlayerText element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'switch':
                                    return <PlayerSwitch element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'image':
                                    return <PlayerImage element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                case 'container':
                                    return <PlayerContainer element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y}/>
                                default:
                                break;
                            }
                        })
                    } 
                </Layer>
            </Stage>
    );
}

export default PlayerCanvas;