import React, { useCallback, useEffect, useState } from "react";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import '../../elementpanels/elementpanel.css';
import { ChevronDownIcon } from "@radix-ui/react-icons";

const FontSize = ({item, setFontSize, onChange}) => {

  const [fontSizeSelect, setFontSizeSelect] = useState(item.font_size);
  const arr = [2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,42,44,46,48,50,52,54,56,58,60,62,64,66,68,70,72]

  useEffect(()=>{
    setFontSizeSelect(item.font_size);
  },[item])

 
  return (
    <div>
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <div className="element-panel-dropdown-trigger">
                    <p>Font size</p>
                    <button aria-label="Customise options">
                        {fontSizeSelect}
                        <ChevronDownIcon color="#777FBD" width={16} height={16}/>
                    </button>
                </div>
                
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="element-panel-dropdown-content">

                    {arr.map((size) => {
                        return (
                            <DropdownMenu.Item className="DropdownMenuItem" onClick={()=>{setFontSizeSelect(size); setFontSize(size); onChange(size)}}>
                            {size}
                            </DropdownMenu.Item>
                        );
                    })}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    </div>
  );
};

export default FontSize