export function RepositionElements (designScreen, playerscreen) {
  // get xpos ypos from design screen
  // get xpos ypos from player screen 
  // take design screen from proto screen

  const xpos = playerscreen.xpos - designScreen.xpos;
  const ypos = playerscreen.ypos - designScreen.ypos;

  //console.log(xpos, ypos)

  return {xpos: xpos, ypos: ypos};
}