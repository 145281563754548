import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Stage, Layer, Rect, Text as KonvaText } from 'react-konva';
import Konva from 'konva';
import Leftsp from '../../components/ui/leftsp/leftsp';
import Rightsp from '../../components/ui/rightsp/rightsp';
import CenterPanel from '../../components/ui/centerpanel/centerpanel';
import Canvas from '../../components/canvas/canvas';
import ToolBar from '../../components/ui/toolbar/toolbar';
import { useEditorContext } from '../../context/editor/editorcontext';
import { useLocation, useParams } from 'react-router-dom';
import { useAuthContext } from '../../context/auth/authcontext';
import { supabase } from '../../supabase/supabaseclient';

function Editor(){

    const editorContext = useEditorContext();
    const authContext = useAuthContext();
    const [loaded, setLoaded] = useState(false);
    const { id } = useParams();

    useEffect(()=>{
        const loadFile = async() => {
            const { data, error } = await supabase
            .from('Files')
            .select()
            .eq('id', id);

            if(data){
                editorContext.setCurrentFileDetails(data[0].file_name, data[0].screen_size);
                //console.log('screen leng',editorContext.screens.length )
                // if(editorContext.screens.length === 0){
                //     editorContext.addScreen(data[0].screen_size);
                // }
            }
    
            if(error){
                console.log(error);
            }
          }
          loadFile();
    },[])

    useEffect(()=>{
        // screen to be loaded from app
        // onload add screen
        if(loaded == false){
            setLoaded(true);
            console.log('load elements')
            editorContext.loadScreens(id);
            editorContext.setCurrentFileFn(id);
            editorContext.loadElements(id);           
        }
        
    },[]);

    useEffect(()=>{
        console.log('about to sort elements', editorContext.loaded)
        if(editorContext.loaded){
            // elements are loaded
            // sort elements
            editorContext.sortElements()
        }
    },[editorContext.loaded]);


    useEffect(()=>{
        //load screens - if none add one screen
        if(editorContext.currentFile && editorContext.currentFileScreenSize && editorContext.screensLoaded){
            // console.log('file id loaded', editorContext.currentFile);
            // console.log('screens', editorContext.screens);
            // console.log('screens loaded',editorContext.screensLoaded)
           if(editorContext.screens.length == 0){
            console.log('going to add screen', editorContext.currentFileScreenSize);
                editorContext.addScreen(editorContext.currentFileScreenSize);
           }
        }
    },[editorContext.currentFile, editorContext.currentFileScreenSize, editorContext.screensLoaded])

    useEffect(()=>{

        if(editorContext.screensLoaded && editorContext.newScreenFlag){
            const currentScreen = editorContext.screens.filter((i) => i.isVisible == true); 
            const newScreen = editorContext.screens[editorContext.screens.length-1]
            const oldProps = {
                id: currentScreen[0].id,
                isVisible: false
            }
            const props = {
                id: newScreen.id,
                isVisible: true
            }
    
            //console.log('this is the screen', screen);

            ////Important to stop infinite loop.
            editorContext.setNewScreenFlag(false);
            
            editorContext.setScreenVisible(props, oldProps);
        }

    },[editorContext.screens])

    useEffect(()=>{
        const loadUser = async() => {
            const { data, error } = await supabase
            .from('Users')
            .select()
            .eq('auth_id', authContext.session.user.id)
    
            if(data){
                console.log(data[0])
                authContext.setUserFn(data[0]);
            }
    
            if(error){
                console.log(error);
            }
          }

          if(!authContext.user && authContext.session){
            loadUser();
          }
          
    },[])

    return(
        <Fragment>
            <ToolBar/>
            <Leftsp/>
            <Rightsp fileId={id}/>
            <Canvas fileId={id} />
        </Fragment>
    );
}

export default Editor;