import React, { useEffect, useState } from 'react';
import './rightsp.css'
import { useEditorContext } from '../../../context/editor/editorcontext';
import { animated, useSpringValue } from '@react-spring/web';
import StylePanel from './components/style/stylepanel';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import TextFieldPanel from './components/elementpanels/textfield/textfieldpanel';
import ButtonPanel from './components/elementpanels/button/buttonpanel';
import TextPanel from './components/elementpanels/text/textpanel';
import ImagePanel from './components/elementpanels/image/imagepanel';
import SwitchPanel from './components/elementpanels/switch/switchpanel';

function Rightsp({fileId}){

    // styling tab
    const editorContext = useEditorContext();
    const visible = useSpringValue(0);
    const [item, setItem] = useState(null);
    const width = 230;
    const height = 500;

    
    return(
        <div className='rspcontainer' style={{right: visible, width: visible}}>
            <div className='rspscroll'>
                {
                  editorContext.activeElement &&
                    <div>
                        {
                            editorContext.activeElement?.type == 'textfield' &&
                            <>
                                <TextFieldPanel item={editorContext.activeElement}/>
                                <StylePanel item={editorContext.activeElement}/>
                            </>
                           
                        }
                        {
                            editorContext.activeElement?.type == 'button' &&
                            <>
                                <ButtonPanel item={editorContext.activeElement}/>
                                <StylePanel item={editorContext.activeElement}/>
                            </>
                           
                        }
                        {
                            editorContext.activeElement?.type == 'text' &&
                            <>
                                <TextPanel item={editorContext.activeElement}/>
                            </>
                           
                        }
                        {
                            editorContext.activeElement?.type == 'image' &&
                            <>
                                <ImagePanel item={editorContext.activeElement} fileId={fileId}/>
                                <StylePanel item={editorContext.activeElement}/>
                            </>
                           
                        }
                        {
                            editorContext.activeElement?.type == 'switch' &&
                            <>
                                <SwitchPanel item={editorContext.activeElement}/>
                                <StylePanel item={editorContext.activeElement}/>
                            </>
                           
                        }
                        {
                            editorContext.activeElement?.type == 'container' &&
                            <>
                                <StylePanel item={editorContext.activeElement}/>
                            </>
                           
                        }
                        
                    </div>
                }
                
                
            </div>
        </div>    
    );
}

export default Rightsp;