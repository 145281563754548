import React, { useState, useEffect, useRef, Fragment } from 'react';
import { supabase } from '../../../supabase/supabaseclient';
import { useNavigate, Link, useParams } from 'react-router-dom';
import * as Slider from "@radix-ui/react-slider";
import { useAuthContext } from '../../../context/auth/authcontext';
import './yourteam.css';

function YourTeam(){

    const navigate = useNavigate();
    const authContext = useAuthContext();

    const { in_signup, company, team, team_id } = useParams();

    const [teamName, setTeamName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [numOfUsers, setNumOfUsers] = useState(3);
    const [errorState, setErrorState] = useState('');

    console.log('this is in_signup', in_signup, company, team, team_id);


    async function handleTeam() {

        if(in_signup == 'true' && (!companyName || !teamName)){
            setErrorState('Please fill out all fields');
            return;
        }


        if(in_signup == 'true'){
            await supabase
            .from('Teams')
            .insert({
                team_name: teamName,
                company_name: companyName,
                number_of_seats: parseInt(numOfUsers),
            })
            .select()
            .then(async(res)=>{
                console.log('team', res);
                console.log(authContext.user)
                if(res.error){
                    setErrorState('There has been an error');
                    return;
                }
                const {data, error} = await supabase
                .from('Members')
                .insert({
                    member_id: authContext.user.id,
                    team_id: res.data[0].id
                }).then(()=>{
                    navigate(`/signup/checkout/${parseInt(numOfUsers)}/${res.data[0].id}`);
                })
    
                if(error){
                    console.log(error);
                }
    
            }).catch((error)=>{
                console.log(error);
            })
        }else{
            navigate(`/signup/checkout/${parseInt(numOfUsers)}/${team_id}`);
        }

        

    }

    return (
        <div className='signup-main'>
            <div className='signup-header-container'>
                <h1 className="signup-header">Rivveo <span>BETA</span></h1>

            </div>
            <div className='signup-box'>
                <div className='signup-header-container'>
                    <h1 className='selectplan-box-header'>Your Team</h1>

                    {
                        in_signup == 'true' ? 
                        <div className='signup-progress'>
                            <p>3/5</p>
                        </div>
                        :
                        null
                    }
                    
                </div>
                <div className='signup-box-input'>
                    {
                        in_signup == 'true' ? 
                            <>
                                <label>Your company</label>
                                <input type="text" id="companyname" value={companyName} placeholder="Your Company Name" onChange={(e) => setCompanyName(e.target.value)}/>
                            </>
                        :
                            <>
                                <label>Company</label>
                                <p style={{fontSize: 18, color: '#8DB3E5'}}>{company}</p>
                            </>
                    }
                    
                </div>
                <div className='signup-box-input'>
                    {
                        in_signup == 'true' ? 
                            <>
                                <label>Name your rivveo team</label>
                                <input type="text" id="teamname" value={teamName} placeholder="Your Team Name" onChange={(e) => setTeamName(e.target.value)}/>
                            </>
                        :
                            <>
                                <label>Team</label>
                                <p style={{fontSize: 18, color: '#8DB3E5'}}>{team}</p>
                            </>
                    }
                    
                </div>
                <div className='signup-box-input'>
                    <label>Number of users</label>
                    <div className='your-team-range-slider'>
                        <div className='your-team-numusers-min'>
                            <p>1 user</p>
                        </div>
                        <Slider.Root className="SliderRoot" defaultValue={[3]} max={35} min={1} step={1} onValueChange={(e)=>setNumOfUsers(e)} >
                            <Slider.Track className="SliderTrack">
                                <Slider.Range className="SliderRange" />
                            </Slider.Track>
                            <Slider.Thumb className="SliderThumb">
                                <div className='SliderThumbInfo'>
                                    <p>{numOfUsers}</p>
                                </div>
                            </Slider.Thumb>
                        </Slider.Root>
                        <div className='your-team-numusers-max'>
                            <p>35 users</p>
                        </div>

                    </div>
                </div>
                <p className='signup-error'>{errorState}</p>
                <button className='yourteam-box-button' onClick={()=>handleTeam()}>
                    Next
                </button>
            </div>
            
        </div>

    )

}

export default YourTeam;