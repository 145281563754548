
export const prototypeSwitcher = (event, element) => {
    // switch statement that calls the right trigger fucntion
    switch (element.proto_trigger) {
        case 'On click':
            if(event == element.proto_trigger){
                return true;
            }
            break;
        case 'On enter': 
            if(event == element.proto_trigger){
                //console.log('this element has a onClick trigger', element);
                return true;
            }
            break;
        case 'Switch on': 
            if(event == element.proto_trigger){
                //console.log('this element has a onClick trigger', element);
                return true;
            }
            break;
        case 'Switch off': 
            if(event == element.proto_trigger){
                //console.log('this element has a onClick trigger', element);
                return true;
            }
            break;
        default:
            return false;
            break;
    }
}
