import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext({
    session: null,
    setUserSession: () => null,
    setUserFn: () => null,
    user: null,
    setTeamFn: () => null,
    team: null,
})

export const AuthContextProvider = (props) => {
    
    const [session, setSession] = useState(null);
    const [user, setUser] = useState(null);
    const [team, setTeam] = useState('Loading');

    useEffect(()=>{
      // keep state updated
      // console.log('in auth context', session);
      setSession(props.sessionData);
    },[props.sessionData])

    const setUserSession = (sessionObj) => {
        //console.log(props.session)
        setSession(sessionObj);
    }

    const setUserFn = (userObj) => {
        setUser(userObj);
    }

    const setTeamFn = (teamObj) => {
        setTeam(teamObj);
    }

    const value = {
        session,
        setUserSession,
        setUserFn,
        user,
        setTeamFn,
        team
    }

    return ( 
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => React.useContext(AuthContext);