import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer, Image, Line } from 'react-konva';
import { Html } from 'react-konva-utils';
import { useEditorContext } from '../../../context/editor/editorcontext';
import { useState, useEffect } from 'react';
import { useAuthContext } from '../../../context/auth/authcontext';
import Trigger from './options/trigger/trigger';
import Interaction from './options/interaction/interaction';
import PassData from './options/passdata/passdata';
import './prototypemenu.css';
import SelectScreen from './options/selectScreen/selectscreen';

function PrototypeMenu({xpos, ypos, width, height}) {

    const editorContext = useEditorContext();
    // menu option states
    const [trigger, setTrigger] = useState(null);
    const [interaction, setInteraction] = useState(null);
    const [navigateTo, setNavigateTo] = useState(null);
    const [showScreens, setShowScreens] = useState(false);

    const menuX = parseFloat(xpos) + parseFloat(width) + 300;
    const menuY = parseFloat(ypos) + parseFloat(height/2);

    const x1 = parseFloat(xpos) + parseFloat(width) + 20
    const y1 = menuY
    const x2 = menuX - 20
    const y2 = menuY + 50;

    return(
        <>
            <Group 
                x={menuX} 
                y={menuY} 
            >   
                <Html>
                <div className='prototype-menu-container'>
                    <Trigger 
                        interaction={interaction}
                        navigateTo={navigateTo}
                        setTrigger={setTrigger}
                    />
                    <Interaction 
                        trigger={trigger}
                        navigateTo={navigateTo}
                        setInteraction={setInteraction}  
                        setShowScreens={setShowScreens}
                    />
                    { showScreens &&
                        <SelectScreen 
                            interaction={interaction}
                            trigger={trigger}
                            setNavigateTo={setNavigateTo}
                            showScreens={showScreens}
                            navigateTo={navigateTo}
                        />
                    }                            
                </div>
                </Html>
            </Group>
            <Circle radius={5} x={x1} y={y1} fill={'#8DB3E5'}/>
            <Circle radius={5} x={x2} y={y2} fill={'#8DB3E5'}/>
            <Line x={0} y={0} points={[x1, y1, x2, y2]} stroke={'#8DB3E5'}/>
        </>
         
    )

}
export default PrototypeMenu