import React, {useEffect, useState} from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import * as Popover from '@radix-ui/react-popover';
import './fillbordercomponent.css';
import { useEditorContext } from "../../../../../../../context/editor/editorcontext";
import '../../../elementpanels/elementpanel.css';

function Fillbordercomponent ({item}) {

    const editorContext = useEditorContext();
    const [fill, setFill] = useState(item.fill);
    const [border, setBorder] = useState(item.border_colour);
    const [borderWidth, setBorderWidth] = useState(item.border_width);

    useEffect(()=>{
        setFill(item.fill);
        setBorder(item.border_colour);
        setBorderWidth(item.border_width);
    },[item])

    const updateElement = (fill, border, borderWidth) => { 
        if(item?.id == editorContext.activeElement?.id){
          const props = { 
              from: 'fillborder',
              type: item.type,
              id: item.id,
              grouped: item.type !== 'container' ? item.grouped : null,
              grouped_by: item.type !== 'container' ? item.grouped_by : null,
              fill: fill,
              border_colour: border,
              border_width: borderWidth
          }
          editorContext.updateElementStyling(props);
        }
    }

    const handleFillBorder = (fill, border, borderWidth) => {
        console.log(fill)
        setFill(fill);
        setBorder(border);
        setBorderWidth(borderWidth);
        updateElement(fill, border, borderWidth);
    }

    return(
    <div className="element-panel-section">
            <Popover.Root>
                <Popover.Trigger asChild>
                    <div className="element-panel-colour">
                        <p>Fill</p>
                        <div>
                            <div style={{width: 24, height: 24, background: fill}}/>
                            <p>{fill}</p>
                        </div>
                        
                    </div>
                    
                </Popover.Trigger>
                <Popover.Portal>
                <Popover.Content className="PopoverContent" sideOffset={5}>
                    <div className="element-panel-colour-picker">
                        <HexColorPicker color={fill} onChange={(e)=>handleFillBorder(e, border, borderWidth)}/>
                        <HexColorInput color={fill} onChange={(e)=>handleFillBorder(e, border, borderWidth)}/>
                    </div>
                </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        
        <Popover.Root>
            <Popover.Trigger asChild>
                <div className="element-panel-colour">
                    <p>Border</p>
                    <div>
                        <div style={{width: 24, height: 24, background: border}}/>
                        <p>{border}</p>
                    </div>
                </div>
            </Popover.Trigger>
            <Popover.Portal>
            <Popover.Content className="PopoverContent" sideOffset={10}>
                <div className="element-panel-colour-picker">
                    <HexColorPicker color={border} onChange={(e)=>handleFillBorder(fill, e, borderWidth)} />
                    <HexColorInput color={border} onChange={(e)=>handleFillBorder(fill, e, borderWidth)} />
                </div>
            </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
        <div>
            <div className="element-panel-input">
                <p>Border width</p>
                <input type="number" defaultValue={item.border_width} onChange={(e)=>handleFillBorder(fill, border, parseInt(e.target.value))}/>
            </div>
        </div>
    </div>
    );
}

export default Fillbordercomponent;