import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../context/auth/authcontext";
import { supabase } from "../../supabase/supabaseclient";
import { useEffect, useState } from "react";

function RequireInvite({ children }) {
    // const { authed } = useAuth();
    const authContext = useAuthContext();
    const { email, id } = useParams();
    const [validate, setValidate] = useState(false);
    const [validateTeam, setValidateTeam] = useState(false);
    const [validateEmail, setValidateEmail] = useState(false);

    const [Loaded, setLoaded] = useState(false);

    const getTeam = async() => {
       const {data, error} = await supabase
        .from('Teams')
        .select()
        .eq('id', id)

        if(data){
            console.log(data);
            console.log('team is validated')
            setValidateTeam(true);
        }

        if(error){
            console.log(error);
            setLoaded(true);
        }
    }

    const getInvite = async() => {
        const {data, error} = await supabase
         .from('Invites')
         .select()
         .eq('email', email)
 
         if(data){
            console.log(data);
            setValidateEmail(true);
         }
 
         if(error){
             console.log(error);
             setLoaded(true)
         }
     }

    useEffect(()=>{

        console.log(id, email);

        if(!email || !id) return setValidate(false);

        getTeam();
        getInvite();        
    },[])

    useEffect(()=>{
        if(validateEmail && validateTeam){
            console.log('NAVIGATE TO PASSWORD PAGE')
            setLoaded(true);
        }
    },[validateEmail, validateTeam, Loaded])

  



    if(!Loaded) return;

    console.log('navigating to page', validateEmail, validateTeam)
    return validateEmail && validateTeam ? children : <Navigate to="/login" replace />;
}

export default RequireInvite;