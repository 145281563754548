import React, { useState, useEffect, useRef, Fragment } from 'react';
import './dashboard.css'
import { PersonIcon, ExitIcon, GearIcon } from '@radix-ui/react-icons';
import { supabase } from '../../supabase/supabaseclient';
import { useAuthContext } from '../../context/auth/authcontext';
import { useEditorContext } from '../../context/editor/editorcontext';
import { Link, useNavigate} from 'react-router-dom';
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import NewFileModal from '../../components/newFileModal/newFileModal';
import DesktopImg from '../../resources/dashboard/desktop.png';
import TabletImg from '../../resources/dashboard/tablet.png';
import MobileImg from '../../resources/dashboard/mobile.png';
import TeamSettings from '../../components/settings/teamsettings/teamsettings';
import InviteMembers from '../../components/settings/invitemembers/invitemembers';


function Dashboard() {

    const authContext = useAuthContext();
    const editorContext = useEditorContext();
    const navigate = useNavigate();
    const [files, setFiles] = useState(null);
    const [loadedFiles, setLoadedFiles] = useState(false);
    const [loadedUser, setLoadedUser] = useState(false);
    const [loadedTeam, setLoadedTeam] = useState(false);
    const [teamSubscription, setTeamSubscription] = useState(null);
    const [teamInfo, setTeamInfo] = useState(null)

    useEffect(()=>{
        editorContext.resetAppStates();
    },[])

    useEffect(()=>{
        const loadUser = async() => {
            const { data, error } = await supabase
            .from('Users')
            .select()
            .eq('auth_id', authContext.session.user.id)
    
            if(data){
                console.log(data[0])
                authContext.setUserFn(data[0]);
                setLoadedUser(true);
            }
    
            if(error){
                console.log(error);
            }
          }

          //console.log('loading user', authContext.user, authContext.session)

          if(!authContext.user && authContext.session){
            console.log('loading user')
            loadUser();
          }
    },[authContext.user, authContext.session])

    useEffect(()=>{
        const getTeam = async() => {
            const { data:memberData, error:memberError } = await supabase
            .from('Members')
            .select(`
                team_id, 
                member_id, 
                Teams(id, team_name, company_name, number_of_seats, stripe_customer_id, status)
            `)
            .eq('member_id', authContext.user.id);
    
            if(memberData){
                // there is no team
                if(memberData.length == 0) { navigate('/signup/your-team/true'); return}
                console.log('member data', memberData)
                authContext.setTeamFn(memberData);
                setTeamInfo(memberData);
                setTeamSubscription(memberData[0].Teams.status);
                setLoadedTeam(true)
            }
    
            if(memberError){
                console.log(memberError);
            }
          }

          if(loadedUser){
            getTeam();
          }
    },[loadedUser])

    // useEffect(()=>{
    //     const stripeCustomerPortal = async() => {
    //         const { data, error } = await supabase.functions.invoke('stripe-customer',{
    //             body:{
    //                 domain: window.location.origin,
    //                 customerId: authContext.team.stripe_customer_id,
    //             }
    //         });

    //         if(data){
    //             console.log('this is customer portal data', data)
    //         }

    //         if(error){
    //             console.log('customer portal error', error)
    //         }
    //     }

    //     stripeCustomerPortal();
    // },[])

    useEffect(()=>{
     //  load design files
      const loadUsersFiles = async() => {
        console.log('team id', authContext.team[0]?.Teams?.id)
        const { data, error } = await supabase
        .from('Files')
        .select()
        .eq('team', authContext.team[0]?.Teams?.id )
        .order('created_at', { ascending: false })

        if(data){
            console.log(data);
            setFiles(data);
            setLoadedFiles(true);
        }

        if(error){
            console.log(error);
        }
      }

      if((!loadedFiles && loadedTeam) || authContext.user){
        loadUsersFiles();
      }
      
    },[loadedTeam]);

    useEffect(()=>{
        console.log(teamSubscription);
        // Check subscription status
        if(teamSubscription === null) return;

        if(teamSubscription == 'active' || teamSubscription == 'incomplete'){
            return;
        }else{
            console.log('team subscription navigating', teamSubscription);
            //Subscription is not valid 
            const company = teamInfo[0].Teams.company_name;
            const team = teamInfo[0].Teams.team_name;
            const team_id = teamInfo[0].Teams.id;
            navigate(`/signup/your-team/false/${company}/${team}/${team_id}`);
        }

    },[teamSubscription])

    const handleSignOut = async() =>  {
        const { error } = await supabase.auth.signOut();
        authContext.setUserFn(null);
        console.log(error)
    }

    const getFileImg = (type) => {
        switch (type) {
            case 'Desktop':
                return DesktopImg;
            case 'Tablet':
                return TabletImg;
            case 'Mobile':
                return MobileImg;
            default:
                break;
        }
    }

    const timestampToDate = (timestamp) => {
        const date = new Date(timestamp).toDateString();
        return date;
    }

    const FileComponent = ({item}) => (
        <div className='file-component'>
            <Link to={`/design/${item.id}`}  state={{id: item.id, file_name: item.file_name, screen_size: item.screen_size}}>
                <div className='file-component-img'>
                    <img src={getFileImg(item.type)}/>
                </div>
                <p className='file-component-title'>{item.file_name}</p>
                <p className='file-component-subtitle'>{item.screen_size}</p>
                <p className='file-component-subtitle'>Created: {timestampToDate(item.created_at)}</p>
            </Link>
        </div>
    );

    return(
        <div className='dashboard'>
            <div className='dashboard-header-container'>
                <div className='dashboard-header-group'>
                    <h1 className="dashboard-header">Rivveo <span>BETA</span></h1>
                    <div className='dashboard-team'>
                        <p className='dashboard-team-title'>Team:</p>
                        <p>{authContext.team[0]?.Teams?.team_name}</p>
                    </div>
                </div>
                <div className='dashboard-settings-group'>
                    <InviteMembers/>
                    <DropdownMenu.Root modal={false}>
                        <DropdownMenu.Trigger asChild>
                            <div className='dashboard-profile trigger'>
                                <PersonIcon width={20} height={20}/>
                            </div>    
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.Content className="dashboard-dropdown-menu-content">
                                {/* <TeamSettings/> */}
                                <DropdownMenu.Item className="dashboard-dropdown-menu-item" onClick={()=>handleSignOut()}>
                                    <ExitIcon/> Sign Out
                                </DropdownMenu.Item>
                            </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                </div>
            </div>
            <ScrollArea.Root className="dashboard-ScrollAreaRoot">
                <ScrollArea.Viewport className="dashboard-ScrollAreaViewport">
                    <div className="dashboard-grid">
                        <NewFileModal/>
                        {
                            loadedFiles && files.map(item =>(
                                <FileComponent item={item}/>
                            ))
                        }
                    </div>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                    className="ScrollAreaScrollbar"
                    orientation="vertical"
                >
                    <ScrollArea.Thumb className="ScrollAreaThumb" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Scrollbar
                    className="ScrollAreaScrollbar"
                    orientation="horizontal"
                >
                    <ScrollArea.Thumb className="ScrollAreaThumb" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Corner className="ScrollAreaCorner" />
            </ScrollArea.Root>
                
        </div>
    )
}

export default Dashboard;