import './toolbar.css';
import { Link } from 'react-router-dom';
import { IconButton } from '@radix-ui/themes';
import { PlayIcon, CopyIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom';
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useEditorContext } from '../../../context/editor/editorcontext';
import CenterPanel from '../centerpanel/centerpanel';

function ToolBar(){

    const editorContext = useEditorContext();
    const navigate = useNavigate();

    return(
        <div className='tb-container'>

            <div>
                <div className='tb-header'>
                    <button className='tb-logo' onClick={()=>{navigate('/dashboard')}}>
                        <h3>Rivveo <span>BETA</span></h3>
                    </button>
                    <div>
                        <p>{editorContext.currentFileName}</p>
                    </div>
                </div>
            </div>
            
            <div className='tb-elements'>
                <CenterPanel/>
            </div>
            

            <div>
                <div className='tb-share'>

                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                            <div className='tb-share-button trigger'>
                                <p>Share</p>
                            </div>    
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.Content className="dashboard-dropdown-menu-content">
                                <div className='tb-share-dropdown-title'>
                                    <p>Share</p>
                                </div>
                                {/* <div className='tb-share-dropdown-access'>
                                    <p>People with access to this file</p>
                                </div> */}
                                <div className='tb-share-dropdown-link'>
                                    <p>Share link to prototype</p>
                                    <button onClick={()=>{navigator.clipboard.writeText(`${window.location.origin}/proto/${editorContext.currentFile}`)}}>
                                        <CopyIcon/>
                                        <p>Copy prototype link</p>
                                    </button>
                                </div>
                            </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Root>

                    <Link className='tb-play-button' to={`/proto/${editorContext.currentFile}`} target='_blank'>
                        <IconButton>
                            <p>Play</p>
                            <PlayIcon  color='#38E577' width={18} height={18}/>
                        </IconButton>
                    </Link>
                </div>
            </div>
            

        </div>
    );
}

export default ToolBar;