import React, {useRef, useEffect, useLayoutEffect, useState, useId} from "react";
import { Group, Layer, Line } from 'react-konva';
import CoreContainer from "./core/corecontainer";
import { useEditorContext } from "../../../../context/editor/editorcontext";
import ContextMenu from "../../../ui/contextmenu/contextmenu";
import { rectInRect, ptInRect } from '../../utils/elementinscreen'
import { calcWidthHeight } from "../../../../utils/editor/calcWidthHeight";
import PrototypeMenu from "../../../ui/prototypemenu/prototypemenu";
import { ElementOverContainer } from "../../utils/elementovercontainer";
import { onDragMoveOS } from "../../utils/objectsnapping";


function Container({item, stageRef, fileId}) {

    const [selectedId, setSelectedId] = useState(null);
    const editorContext = useEditorContext();
    const [domensions, setDomensions] = useState({x: 0, y: 0});

    const [hLines, setHLines] = useState([]);
    const [vLines, setVLines] = useState([]);

    const [width, setWidth] = useState(item.width);
    const [height, setHeight] = useState(item.height);
    const [xpos, setXpos] = useState(item.xpos);
    const [ypos, setYpos] = useState(item.ypos); 
    //all options for toolbar
    const [contextMenuOptions, setContextMenuOptions] = useState(
        {
            x: item.xpos,
            y: item.ypos,
        }
    )

    useEffect(()=>{
        // get screen with element id
        if(editorContext.isCanvasSelected){
            setSelectedId(null);
        }
    },[editorContext.isCanvasSelected])
    
    return(
        <Group>
                <>
                    { editorContext.screens.some((e)=> e.isVisible === true && e.id == item.screen) ?
                    <>
                        <CoreContainer 
                            stageRef={stageRef}
                            keyVal={item.id}
                            fileId={fileId}
                            shapeProps={item}
                            setDomensions={setDomensions}
                            isSelected={item.id == selectedId && selectedId == editorContext.activeElement?.id}
                            setSelectedId={setSelectedId}
                            elWidth={width}
                            elHeight={height}
                            onSelect={(e) => {
                                console.log('container selected');
                                //trigger transformer
                                setSelectedId(item.id);  
                                // set active element
                                editorContext.setCurrentActiveElement(item);
                                // update context menu
                                setContextMenuOptions({x: item.xpos, y: item.ypos})
                            }}
                            onDragMove={(e)=>{  
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id){
                                    return;
                                }

                                e.cancelBubble = true;

                                const snapLines = onDragMoveOS(stageRef, e.target);
                                //console.log(snapLines)
 
                                if (snapLines.length > 0) {
                                 setVLines(snapLines
                                   .filter(line => line.type === 'vertical')
                                   .map(line => ({
                                     points: [0, -6000, 0, 6000],
                                     x: line.x,
                                     y: 0,
                                     stroke: '#D138E5',
                                     strokeWidth: 1,
                                   }))
                                 );
                                 
                                 setHLines(snapLines
                                   .filter(line => line.type === 'horizontal')
                                   .map(line => ({
                                     points: [-6000, 0, 6000, 0],
                                     x: 0,
                                     y: line.y,
                                     stroke: '#D138E5',
                                     strokeWidth: 1,
                                   }))
                                 );
                                 } else {
                                     // Clear lines if no snap points found
                                     setVLines([]);
                                     setHLines([]);
                                 }

                                //console.log('moving container', e);
                                const x = e.target.attrs.x;
                                const y = e.target.attrs.y;
                                setXpos(parseFloat(x.toFixed(3)));
                                setYpos(parseFloat(y.toFixed(3)));
                                // update context menu
                                setContextMenuOptions({x: parseFloat(x.toFixed(3)), y: parseFloat(y.toFixed(3)) });
                            }}
                            onDragEnd={async(e)=>{
                                setSelectedId(item.id);  
                                // set active element
                                editorContext.setCurrentActiveElement(item);
                               if(item.id != selectedId && selectedId != editorContext.activeElement?.id){
                                return;
                               }
                               e.cancelBubble = true;

                               setVLines([]);
                               setHLines([]);

                               //check if element is inside the screen
                               //get screen
                               const currentScreen = editorContext.screens.filter((i) => i.isVisible == true);
                               //get element 
                               const currentElement = e.target.attrs;

                            //    const isContainer = await ElementOverContainer(stageRef, editorContext.element, item);
                            //    console.log('this is switch container pos', isContainer);
    
                               const r1 = { 
                                   left: currentScreen[0].xpos,
                                   top: currentScreen[0].ypos,
                                   right: currentScreen[0].xpos + currentScreen[0].width,
                                   bottom: currentScreen[0].ypos + currentScreen[0].height
                                }
                               const r2 = { 
                                   left: currentElement.x,
                                   top: currentElement.y,
                                   right: currentElement.x,
                                   bottom: currentElement.y,
                                   width: item.width,
                                   height: item.height,
                               }

                               const isElementinScreen =  rectInRect(r1, r2);
                               const x = e.target.attrs.x;
                               const y = e.target.attrs.y;
                               const props = {
                                    from: 'dragEnd',
                                    id: item.id,
                                    xpos: parseFloat(x.toFixed(3)),
                                    ypos: parseFloat(y.toFixed(3)),
                                    in_screen: isElementinScreen,
                                    type: 'container'
                                }
                                console.log('container props', props);
                               
                               editorContext.updateElement(props);


                            //    if(isContainer && item.grouped_by !== isContainer.id){
                            //     // there is a container
                            //     // and the element is not already grouped by container
                            //     console.log('adding to container');
                            //     // element is in container
                            //     editorContext.makeElementContainerChild(item, props, isContainer);
                            //    }else if(item.grouped && isContainer == false){
                            //     // element is grouped by a container
                            //     // but is currently over no container meaning 
                            //     // they've moved the element outside of the container
                            //     console.log('removing from container');
                            //     editorContext.removeElementContainerChild(item, props);
                            //    }
                            }}
                            onTransform={(e)=>{
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id){
                                    return;
                                }
                                //console.log(calcWidthHeight(e.target.attrs.scaleX,  e.target.attrs.width).toFixed(2));
                                //console.log(calcWidthHeight(e.target.attrs.scaleY, e.target.attrs.height).toFixed(2), e.target.attrs.scaleY, e.target.attrs.height);
                                const x = e.target.attrs.x;
                                const y = e.target.attrs.y;

                                // for updating element width/height without making db request
                                setWidth(parseFloat(calcWidthHeight(e.target.attrs.scaleX,  e.target.attrs.width).toFixed(2)));
                                setHeight(parseFloat(calcWidthHeight(e.target.attrs.scaleY, e.target.attrs.height).toFixed(2)));

                                setContextMenuOptions({x: parseFloat(x.toFixed(3)), y: parseFloat(y.toFixed(3))});
                                //setYpos(parseFloat(y.toFixed(3)));
                                //setXpos(parseFloat(x.toFixed(3)))

                            }}
                            onTransformEnd={(e)=>{
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id){
                                    return;
                                }

                                const props = {
                                    from: 'transformEnd',
                                    id: item.id,
                                    grouped: item.grouped,
                                    grouped_by: item.grouped_by,
                                    width: parseFloat(calcWidthHeight(e.target.attrs.scaleX,  e.target.attrs.width).toFixed(2)),
                                    height: parseFloat(calcWidthHeight(e.target.attrs.scaleY, e.target.attrs.height).toFixed(2)),
                                    scaleX: parseFloat(e.target.attrs.scaleX.toFixed(2)),
                                    scaleY: parseFloat(e.target.attrs.scaleY.toFixed(2)),
                                    type: 'container'
                                }
                                //console.log(props);
                                editorContext.updateElement(props)   
                                // e.target.scale({ x: 1, y: 1 });
                                e.target.setAttrs({scaleX: 1, scaleY:1});
                            }}
                        />
                            {
                                selectedId == editorContext.activeElement?.id &&
                                    <ContextMenu 
                                        contextMenuOptions={contextMenuOptions} 
                                        item={item}
                                        isSelected={item.id === selectedId}
                                        domensions={domensions}
                                        xpos={xpos} 
                                        ypos={ypos} 
                                        width={width} 
                                        height={height}
                                    /> 
                            }

                            {                            
                                selectedId == editorContext.activeElement?.id && editorContext.activeElement?.prototyping 
                                && !editorContext?.isCanvasSelected && !editorContext?.aScreenIsActive &&
                                    <PrototypeMenu xpos={xpos} ypos={ypos} width={width} height={height} /> 
                            }
                    </>
                        :
                        null
                    }
                </>

                { item.id == selectedId && selectedId == editorContext.activeElement?.id && (
                    <>
                        {hLines.map((item, i) => (
                            <Line key={i} {...item} />
                        ))}
                        {vLines.map((item, i) => (
                            <Line key={i} {...item} />
                        ))}
                    </>
                )
                } 
        </Group>
     
    )

}

export default Container;