import React, { useEffect, useState } from "react";
import { useEditorContext } from "../../../../../../../context/editor/editorcontext";
import '../../../elementpanels/elementpanel.css';
import { CornerTopLeftIcon, CornerTopRightIcon, CornerBottomLeftIcon, CornerBottomRightIcon } from "@radix-ui/react-icons";

function BorderRadiusComponent({item}) {

    const editorContext = useEditorContext();
    const [tl, setTl] = useState(item.border_radius_tl);
    const [tr, setTr] = useState(item.border_radius_tr);
    const [bl, setBl] = useState(item.border_radius_bl);
    const [br, setBr] = useState(item.border_radius_br);

    useEffect(()=>{
      setTl(item.border_radius_tl);
      setTr(item.border_radius_tr);
      setBl(item.border_radius_bl);
      setBr(item.border_radius_br);
    },[item])

    const updateElement = (tl, tr, bl, br) => { 
        if(item?.id == editorContext.activeElement?.id){
          const props = { 
              from: 'borderRadius',
              type: item.type,
              id: item.id,
              grouped: item.type !== 'container' ? item.grouped : null,
              grouped_by: item.type !== 'container' ? item.grouped_by : null,
              border_radius_tl: tl,
              border_radius_tr: tr,
              border_radius_bl: bl,
              border_radius_br: br,
          }
          editorContext.updateElementStyling(props);
        }
    }

    const handleBorderRadius = (tl, tr, bl, br) => {
       
       const topLeft = isNaN(tl) ? 0 : tl;
       const topRight = isNaN(tr) ? 0 : tr;
       const bottomLeft = isNaN(bl) ? 0 : bl;
       const bottomRight = isNaN(br) ? 0 : br;
       console.log('top left', tl, topLeft)

        setTl(topLeft);
        setTr(topRight);
        setBl(bottomLeft);
        setBr(bottomRight);
        updateElement(topLeft, topRight, bottomLeft, bottomRight);
    }

    return(
        <div className="element-panel-border-radius">
            <p>Border radius</p>
            <div className="element-panel-border-radius-grid">
                <div>
                    <CornerTopLeftIcon color="#777FBD" width={20} height={20}/>
                    <input value={tl} onChange={(e)=>{handleBorderRadius(parseInt(e.target.value), tr, bl, br)} }/>
                </div>
                <div>
                    <CornerTopRightIcon color="#777FBD" width={20} height={20}/>
                    <input value={tr} onChange={(e)=>{handleBorderRadius(tl, parseInt(e.target.value), bl, br)}} />
                </div>
                <div>
                    <CornerBottomLeftIcon color="#777FBD" width={20} height={20}/>
                    <input value={bl} onChange={(e)=>{handleBorderRadius(tl, tr, parseInt(e.target.value), br)}} />
                </div>
                <div>
                    <CornerBottomRightIcon color="#777FBD" width={20} height={20}/>
                    <input value={br} onChange={(e)=>{handleBorderRadius(tl, tr, bl, parseInt(e.target.value))}} />
                </div>
            </div>
        </div>
    )
}

export default BorderRadiusComponent;