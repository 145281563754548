import React, { useEffect, useState } from "react"
import * as Popover from '@radix-ui/react-popover';
import './buttonpanel.css'
import { useEditorContext } from "../../../../../../context/editor/editorcontext";
import FontPicker from "../../fontpicker/fontpicker";
import FontSize from "../../fontpicker/fontsize/fontsize";
import { HexColorPicker, HexColorInput } from "react-colorful";
import '../../elementpanels/elementpanel.css';

function ButtonPanel ({item}){

    const editorContext = useEditorContext();
    const [value, setValue] = useState(item.value);
    const [font, setFont] = useState(item.font);
    const [fontSize, setFontSize] = useState(item.font_size);
    const [fontColour, setFontColour] = useState(item.font_colour);
    const [hoverFill, setHoverFill] = useState(item.hover_fill);
    const [hoverBorderColour, setHoverBorderColour] = useState(item.hover_border_colour);
    const [hoverBorderWidth, setHoverBorderWidth] = useState(item.hover_border_width);

    useEffect(()=>{
        setValue(item.value);
        setFont(item.font);
        setFontSize(item.font_size);
        setFontColour(item.font_colour);
        setHoverFill(item.hover_fill);
        setHoverBorderColour(item.hover_border_colour);
        setHoverBorderWidth(item.hover_border_width);
    },[item])

    const updateElement = (value, font, fontSize, fontColour, hoverFillVal, hoverBorderColourVal, hoverBorderWidthVal) => { 
        if(item?.id == editorContext.activeElement?.id){
            const props = { 
                type: item.type,
                id: item.id,
                grouped: item.grouped,
                grouped_by: item.grouped_by,
                value: value,
                font: font,
                font_size: fontSize,
                font_colour: fontColour,
                hover_fill: hoverFillVal,
                hover_border_colour: hoverBorderColourVal,
                hover_border_width: hoverBorderWidthVal,
            }
            editorContext.updateElementCustomPanel(props);
        }
    }

    const handlePanel = (val, fontVal, fontSizeVal, fontColourVal, hoverFillVal, hoverBorderColourVal, hoverBorderWidthVal) => {
        setValue(val);
        setFont(fontVal);
        setFontSize(fontSizeVal);
        setFontColour(fontColourVal);
        setHoverFill(hoverFillVal);
        setHoverBorderColour(hoverBorderColourVal);
        setHoverBorderWidth(hoverBorderWidthVal);
        updateElement(val, fontVal, fontSizeVal, fontColourVal, hoverFillVal, hoverBorderColourVal, hoverBorderWidthVal);
    }

    return(
        <div className="element-panel-container">
            <div className="element-panel-title">
                <p>Button</p>
            </div>
            <div className="element-panel-section">
                <div className="element-panel-input">
                    <p>Title</p>
                    <input value={value} onChange={(e)=>handlePanel(e.target.value, font, fontSize, fontColour, hoverFill, hoverBorderColour, hoverBorderWidth)}/>
                </div>
            </div>
            <div className="element-panel-section">
                <div>
                    <FontPicker item={item} setFont={setFont} onChange={(e)=>handlePanel(value, e, fontSize, fontColour, hoverFill, hoverBorderColour, hoverBorderWidth)}/>
                </div>
                <div>
                    <FontSize item={item} setFontSize={setFontSize} onChange={(e)=>handlePanel(value, font, e, fontColour, hoverFill, hoverBorderColour, hoverBorderWidth)}/>
                </div>
                <div>
                    <Popover.Root>
                        <Popover.Trigger asChild>
                            <div className="element-panel-colour">
                                <p>Font colour</p>
                                <div>
                                    <div style={{width: 24, height: 24, background: fontColour}}/> 
                                    <p>{fontColour}</p>
                                </div>
                            </div>
                        </Popover.Trigger>
                        <Popover.Portal>
                        <Popover.Content className="PopoverContent" sideOffset={10}>
                            <div className="element-panel-colour-picker">
                                <HexColorPicker color={fontColour} onChange={(e)=>handlePanel(value, font, fontSize, e, hoverFill, hoverBorderColour, hoverBorderWidth)} />
                                <HexColorInput color={fontColour} onChange={(e)=>handlePanel(value, font, fontSize, e, hoverFill, hoverBorderColour, hoverBorderWidth)} />
                            </div>
                        </Popover.Content>
                        </Popover.Portal>
                    </Popover.Root>
                </div>

            </div>
            
            
            <div>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <div className="element-panel-colour">
                            <p>Hover fill</p>
                            <div>
                                <div style={{width: 24, height: 24, background: hoverFill}}/>
                                <p>{hoverFill}</p>
                            </div>
                            
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                    <Popover.Content className="PopoverContent" sideOffset={10}>
                        <div className="element-panel-colour-picker">
                            <HexColorPicker color={hoverFill} onChange={(e)=>handlePanel(value, font, fontSize, fontColour, e, hoverBorderColour, hoverBorderWidth)} />
                            <HexColorInput color={hoverFill} onChange={(e)=>handlePanel(value, font, fontSize, fontColour, e, hoverBorderColour, hoverBorderWidth)} />
                        </div>
                    </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
            <div>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <div className="element-panel-colour">
                            <p>Hover border</p>
                            <div>
                                <div style={{width: 24, height: 24, background: hoverBorderColour}}/>
                                <p>{hoverBorderColour}</p>
                            </div>
                            
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                    <Popover.Content className="PopoverContent" sideOffset={10}>
                        <div className="element-panel-colour-picker">
                            <HexColorPicker color={hoverBorderColour} onChange={(e)=>handlePanel(value, font, fontSize, fontColour, hoverFill, e, hoverBorderWidth)} />
                            <HexColorInput color={hoverBorderColour} onChange={(e)=>handlePanel(value, font, fontSize, fontColour, hoverFill, e, hoverBorderWidth)} />
                        </div>
                    </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
            <div>
                <div className="element-panel-input">
                    <p>Hover border width</p>
                    <input type="number" value={hoverBorderWidth} onChange={(e)=>handlePanel(value, font, fontSize, fontColour, hoverFill, hoverBorderColour, parseInt(e.target.value))}/>
                </div>
            </div>
        </div>
    )
}

export default ButtonPanel