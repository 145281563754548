import React from "react";
import { usePrototypeContext } from "../../../context/prototype/prototypecontext";
import "./playerheader.css";

function PlayerHeader (){

    const prototypeContext = usePrototypeContext();
    const name = prototypeContext?.file?.file_name;

   // console.log(prototypeContext.file)


    return(
        <div className="player-container">
            <div className='player-header'>
                <button className='player-logo'>
                    <h3>Rivveo <span>BETA</span></h3>
                </button>
                <div>
                    <p>{name}</p>
                </div>
            </div>
        </div>
    )
}

export default PlayerHeader;