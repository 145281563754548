import { GoogleFont } from "@remotion/google-fonts";
 
export const fontSelection = [
  {
    family: "ABeeZee",
    load: () => import("@remotion/google-fonts/ABeeZee") ,
  },
  {
    family: "Abel",
    load: () => import("@remotion/google-fonts/Abel") ,
  },
  {
    family: "Abril Fatface",
    load: () =>
      import("@remotion/google-fonts/AbrilFatface") ,
  },
  {
    family: "Acme",
    load: () => import("@remotion/google-fonts/Acme") ,
  },
  {
    family: "Alata",
    load: () => import("@remotion/google-fonts/Alata") ,
  },
  {
    family: "Albert Sans",
    load: () =>
      import("@remotion/google-fonts/AlbertSans") ,
  },
  {
    family: "Alegreya",
    load: () =>
      import("@remotion/google-fonts/Alegreya") ,
  },
  {
    family: "Alegreya Sans",
    load: () =>
      import("@remotion/google-fonts/AlegreyaSans") ,
  },
  {
    family: "Alegreya Sans SC",
    load: () =>
      import("@remotion/google-fonts/AlegreyaSansSC") ,
  },
  {
    family: "Alfa Slab One",
    load: () =>
      import("@remotion/google-fonts/AlfaSlabOne") ,
  },
  {
    family: "Alice",
    load: () => import("@remotion/google-fonts/Alice") ,
  },
  {
    family: "Almarai",
    load: () => import("@remotion/google-fonts/Almarai") ,
  },
  {
    family: "Amatic SC",
    load: () =>
      import("@remotion/google-fonts/AmaticSC") ,
  },
  {
    family: "Amiri",
    load: () => import("@remotion/google-fonts/Amiri") ,
  },
  {
    family: "Antic Slab",
    load: () =>
      import("@remotion/google-fonts/AnticSlab") ,
  },
  {
    family: "Anton",
    load: () => import("@remotion/google-fonts/Anton") ,
  },
  {
    family: "Architects Daughter",
    load: () =>
      import(
        "@remotion/google-fonts/ArchitectsDaughter"
      ) ,
  },
  {
    family: "Archivo",
    load: () => import("@remotion/google-fonts/Archivo") ,
  },
  {
    family: "Archivo Black",
    load: () =>
      import("@remotion/google-fonts/ArchivoBlack") ,
  },
  {
    family: "Archivo Narrow",
    load: () =>
      import("@remotion/google-fonts/ArchivoNarrow") ,
  },
  {
    family: "Arimo",
    load: () => import("@remotion/google-fonts/Arimo") ,
  },
  {
    family: "Arsenal",
    load: () => import("@remotion/google-fonts/Arsenal") ,
  },
  {
    family: "Arvo",
    load: () => import("@remotion/google-fonts/Arvo") ,
  },
  {
    family: "Asap",
    load: () => import("@remotion/google-fonts/Asap") ,
  },
  {
    family: "Asap Condensed",
    load: () =>
      import("@remotion/google-fonts/AsapCondensed") ,
  },
  {
    family: "Assistant",
    load: () =>
      import("@remotion/google-fonts/Assistant") ,
  },
  {
    family: "Barlow",
    load: () => import("@remotion/google-fonts/Barlow") ,
  },
  {
    family: "Barlow Condensed",
    load: () =>
      import("@remotion/google-fonts/BarlowCondensed") ,
  },
  {
    family: "Barlow Semi Condensed",
    load: () =>
      import(
        "@remotion/google-fonts/BarlowSemiCondensed"
      ) ,
  },
  {
    family: "Be Vietnam Pro",
    load: () =>
      import("@remotion/google-fonts/BeVietnamPro") ,
  },
  {
    family: "Bebas Neue",
    load: () =>
      import("@remotion/google-fonts/BebasNeue") ,
  },
  {
    family: "Bitter",
    load: () => import("@remotion/google-fonts/Bitter") ,
  },
  {
    family: "Black Ops One",
    load: () =>
      import("@remotion/google-fonts/BlackOpsOne") ,
  },
  {
    family: "Bodoni Moda",
    load: () =>
      import("@remotion/google-fonts/BodoniModa") ,
  },
  {
    family: "Bree Serif",
    load: () =>
      import("@remotion/google-fonts/BreeSerif") ,
  },
  {
    family: "Bungee",
    load: () => import("@remotion/google-fonts/Bungee") ,
  },
  {
    family: "Cabin",
    load: () => import("@remotion/google-fonts/Cabin") ,
  },
  {
    family: "Cairo",
    load: () => import("@remotion/google-fonts/Cairo") ,
  },
  {
    family: "Cantarell",
    load: () =>
      import("@remotion/google-fonts/Cantarell") ,
  },
  {
    family: "Cardo",
    load: () => import("@remotion/google-fonts/Cardo") ,
  },
  {
    family: "Catamaran",
    load: () =>
      import("@remotion/google-fonts/Catamaran") ,
  },
  {
    family: "Caveat",
    load: () => import("@remotion/google-fonts/Caveat") ,
  },
  {
    family: "Chakra Petch",
    load: () =>
      import("@remotion/google-fonts/ChakraPetch") ,
  },
  {
    family: "Changa",
    load: () => import("@remotion/google-fonts/Changa") ,
  },
  {
    family: "Chivo",
    load: () => import("@remotion/google-fonts/Chivo") ,
  },
  {
    family: "Cinzel",
    load: () => import("@remotion/google-fonts/Cinzel") ,
  },
  {
    family: "Comfortaa",
    load: () =>
      import("@remotion/google-fonts/Comfortaa") ,
  },
  {
    family: "Commissioner",
    load: () =>
      import("@remotion/google-fonts/Commissioner") ,
  },
  {
    family: "Concert One",
    load: () =>
      import("@remotion/google-fonts/ConcertOne") ,
  },
  {
    family: "Cookie",
    load: () => import("@remotion/google-fonts/Cookie") ,
  },
  {
    family: "Cormorant",
    load: () =>
      import("@remotion/google-fonts/Cormorant") ,
  },
  {
    family: "Cormorant Garamond",
    load: () =>
      import("@remotion/google-fonts/CormorantGaramond") ,
  },
  {
    family: "Courgette",
    load: () =>
      import("@remotion/google-fonts/Courgette") ,
  },
  {
    family: "Crete Round",
    load: () =>
      import("@remotion/google-fonts/CreteRound") ,
  },
  {
    family: "Crimson Pro",
    load: () =>
      import("@remotion/google-fonts/CrimsonPro") ,
  },
  {
    family: "Crimson Text",
    load: () =>
      import("@remotion/google-fonts/CrimsonText") ,
  },
  {
    family: "Cuprum",
    load: () => import("@remotion/google-fonts/Cuprum") ,
  },
  {
    family: "DM Sans",
    load: () => import("@remotion/google-fonts/DMSans") ,
  },
  {
    family: "DM Serif Display",
    load: () =>
      import("@remotion/google-fonts/DMSerifDisplay") ,
  },
  {
    family: "DM Serif Text",
    load: () =>
      import("@remotion/google-fonts/DMSerifText") ,
  },
  {
    family: "Dancing Script",
    load: () =>
      import("@remotion/google-fonts/DancingScript") ,
  },
  {
    family: "Didact Gothic",
    load: () =>
      import("@remotion/google-fonts/DidactGothic") ,
  },
  {
    family: "Domine",
    load: () => import("@remotion/google-fonts/Domine") ,
  },
  {
    family: "Dosis",
    load: () => import("@remotion/google-fonts/Dosis") ,
  },
  {
    family: "EB Garamond",
    load: () =>
      import("@remotion/google-fonts/EBGaramond") ,
  },
  {
    family: "Eczar",
    load: () => import("@remotion/google-fonts/Eczar") ,
  },
  {
    family: "El Messiri",
    load: () =>
      import("@remotion/google-fonts/ElMessiri") ,
  },
  {
    family: "Electrolize",
    load: () =>
      import("@remotion/google-fonts/Electrolize") ,
  },
  {
    family: "Encode Sans",
    load: () =>
      import("@remotion/google-fonts/EncodeSans") ,
  },
  {
    family: "Encode Sans Condensed",
    load: () =>
      import(
        "@remotion/google-fonts/EncodeSansCondensed"
      ) ,
  },
  {
    family: "Exo",
    load: () => import("@remotion/google-fonts/Exo") ,
  },
  {
    family: "Exo 2",
    load: () => import("@remotion/google-fonts/Exo2") ,
  },
  {
    family: "Figtree",
    load: () => import("@remotion/google-fonts/Figtree") ,
  },
  {
    family: "Fira Sans",
    load: () =>
      import("@remotion/google-fonts/FiraSans") ,
  },
  {
    family: "Fira Sans Condensed",
    load: () =>
      import("@remotion/google-fonts/FiraSansCondensed") ,
  },
  {
    family: "Fjalla One",
    load: () =>
      import("@remotion/google-fonts/FjallaOne") ,
  },
  {
    family: "Francois One",
    load: () =>
      import("@remotion/google-fonts/FrancoisOne") ,
  },
  {
    family: "Frank Ruhl Libre",
    load: () =>
      import("@remotion/google-fonts/FrankRuhlLibre") ,
  },
  {
    family: "Fraunces",
    load: () =>
      import("@remotion/google-fonts/Fraunces") ,
  },
  {
    family: "Gelasio",
    load: () => import("@remotion/google-fonts/Gelasio") ,
  },
  {
    family: "Gloria Hallelujah",
    load: () =>
      import("@remotion/google-fonts/GloriaHallelujah") ,
  },
  {
    family: "Gothic A1",
    load: () =>
      import("@remotion/google-fonts/GothicA1") ,
  },
  {
    family: "Great Vibes",
    load: () =>
      import("@remotion/google-fonts/GreatVibes") ,
  },
  {
    family: "Gruppo",
    load: () => import("@remotion/google-fonts/Gruppo") ,
  },
  {
    family: "Heebo",
    load: () => import("@remotion/google-fonts/Heebo") ,
  },
  {
    family: "Hind",
    load: () => import("@remotion/google-fonts/Hind") ,
  },
  {
    family: "Hind Madurai",
    load: () =>
      import("@remotion/google-fonts/HindMadurai") ,
  },
  {
    family: "Hind Siliguri",
    load: () =>
      import("@remotion/google-fonts/HindSiliguri") ,
  },
  {
    family: "IBM Plex Mono",
    load: () =>
      import("@remotion/google-fonts/IBMPlexMono") ,
  },
  {
    family: "IBM Plex Sans",
    load: () =>
      import("@remotion/google-fonts/IBMPlexSans") ,
  },
  {
    family: "IBM Plex Sans Arabic",
    load: () =>
      import("@remotion/google-fonts/IBMPlexSansArabic") ,
  },
  {
    family: "IBM Plex Sans Condensed",
    load: () =>
      import(
        "@remotion/google-fonts/IBMPlexSansCondensed"
      ) ,
  },
  {
    family: "IBM Plex Serif",
    load: () =>
      import("@remotion/google-fonts/IBMPlexSerif") ,
  },
  {
    family: "Inconsolata",
    load: () =>
      import("@remotion/google-fonts/Inconsolata") ,
  },
  {
    family: "Indie Flower",
    load: () =>
      import("@remotion/google-fonts/IndieFlower") ,
  },
  {
    family: "Inter",
    load: () => import("@remotion/google-fonts/Inter") ,
  },
  {
    family: "Inter Tight",
    load: () =>
      import("@remotion/google-fonts/InterTight") ,
  },
  {
    family: "Josefin Sans",
    load: () =>
      import("@remotion/google-fonts/JosefinSans") ,
  },
  {
    family: "Josefin Slab",
    load: () =>
      import("@remotion/google-fonts/JosefinSlab") ,
  },
  {
    family: "Jost",
    load: () => import("@remotion/google-fonts/Jost") ,
  },
  {
    family: "Kalam",
    load: () => import("@remotion/google-fonts/Kalam") ,
  },
  {
    family: "Kanit",
    load: () => import("@remotion/google-fonts/Kanit") ,
  },
  {
    family: "Karla",
    load: () => import("@remotion/google-fonts/Karla") ,
  },
  {
    family: "Kaushan Script",
    load: () =>
      import("@remotion/google-fonts/KaushanScript") ,
  },
  {
    family: "Khand",
    load: () => import("@remotion/google-fonts/Khand") ,
  },
  {
    family: "Lato",
    load: () => import("@remotion/google-fonts/Lato") ,
  },
  {
    family: "League Spartan",
    load: () =>
      import("@remotion/google-fonts/LeagueSpartan") ,
  },
  {
    family: "Lexend",
    load: () => import("@remotion/google-fonts/Lexend") ,
  },
  {
    family: "Lexend Deca",
    load: () =>
      import("@remotion/google-fonts/LexendDeca") ,
  },
  {
    family: "Libre Barcode 39",
    load: () =>
      import("@remotion/google-fonts/LibreBarcode39") ,
  },
  {
    family: "Libre Baskerville",
    load: () =>
      import("@remotion/google-fonts/LibreBaskerville") ,
  },
  {
    family: "Libre Caslon Text",
    load: () =>
      import("@remotion/google-fonts/LibreCaslonText") ,
  },
  {
    family: "Libre Franklin",
    load: () =>
      import("@remotion/google-fonts/LibreFranklin") ,
  },
  {
    family: "Lilita One",
    load: () =>
      import("@remotion/google-fonts/LilitaOne") ,
  },
  {
    family: "Lobster",
    load: () => import("@remotion/google-fonts/Lobster") ,
  },
  {
    family: "Lobster Two",
    load: () =>
      import("@remotion/google-fonts/LobsterTwo") ,
  },
  {
    family: "Lora",
    load: () => import("@remotion/google-fonts/Lora") ,
  },
  {
    family: "Luckiest Guy",
    load: () =>
      import("@remotion/google-fonts/LuckiestGuy") ,
  },
  {
    family: "M PLUS 1p",
    load: () => import("@remotion/google-fonts/MPLUS1p") ,
  },
  {
    family: "M PLUS Rounded 1c",
    load: () =>
      import("@remotion/google-fonts/MPLUSRounded1c") ,
  },
  {
    family: "Macondo",
    load: () => import("@remotion/google-fonts/Macondo") ,
  },
  {
    family: "Manrope",
    load: () => import("@remotion/google-fonts/Manrope") ,
  },
  {
    family: "Marcellus",
    load: () =>
      import("@remotion/google-fonts/Marcellus") ,
  },
  {
    family: "Martel",
    load: () => import("@remotion/google-fonts/Martel") ,
  },
  {
    family: "Mate",
    load: () => import("@remotion/google-fonts/Mate") ,
  },
  {
    family: "Mate SC",
    load: () => import("@remotion/google-fonts/MateSC") ,
  },
  {
    family: "Maven Pro",
    load: () =>
      import("@remotion/google-fonts/MavenPro") ,
  },
  {
    family: "Merienda",
    load: () =>
      import("@remotion/google-fonts/Merienda") ,
  },
  {
    family: "Merriweather",
    load: () =>
      import("@remotion/google-fonts/Merriweather") ,
  },
  {
    family: "Merriweather Sans",
    load: () =>
      import("@remotion/google-fonts/MerriweatherSans") ,
  },
  {
    family: "Montserrat",
    load: () =>
      import("@remotion/google-fonts/Montserrat") ,
  },
  {
    family: "Montserrat Alternates",
    load: () =>
      import(
        "@remotion/google-fonts/MontserratAlternates"
      ) ,
  },
  {
    family: "Mukta",
    load: () => import("@remotion/google-fonts/Mukta") ,
  },
  {
    family: "Mulish",
    load: () => import("@remotion/google-fonts/Mulish") ,
  },
  {
    family: "Nanum Gothic",
    load: () =>
      import("@remotion/google-fonts/NanumGothic") ,
  },
  {
    family: "Nanum Gothic Coding",
    load: () =>
      import("@remotion/google-fonts/NanumGothicCoding") ,
  },
  {
    family: "Nanum Myeongjo",
    load: () =>
      import("@remotion/google-fonts/NanumMyeongjo") ,
  },
  {
    family: "Neuton",
    load: () => import("@remotion/google-fonts/Neuton") ,
  },
  {
    family: "Noticia Text",
    load: () =>
      import("@remotion/google-fonts/NoticiaText") ,
  },
  {
    family: "Noto Color Emoji",
    load: () =>
      import("@remotion/google-fonts/NotoColorEmoji") ,
  },
  {
    family: "Noto Kufi Arabic",
    load: () =>
      import("@remotion/google-fonts/NotoKufiArabic") ,
  },
  {
    family: "Noto Naskh Arabic",
    load: () =>
      import("@remotion/google-fonts/NotoNaskhArabic") ,
  },
  {
    family: "Noto Sans",
    load: () =>
      import("@remotion/google-fonts/NotoSans") ,
  },
  {
    family: "Noto Sans Arabic",
    load: () =>
      import("@remotion/google-fonts/NotoSansArabic") ,
  },
  {
    family: "Noto Sans Bengali",
    load: () =>
      import("@remotion/google-fonts/NotoSansBengali") ,
  },
  {
    family: "Noto Sans Display",
    load: () =>
      import("@remotion/google-fonts/NotoSansDisplay") ,
  },
  {
    family: "Noto Sans HK",
    load: () =>
      import("@remotion/google-fonts/NotoSansHK") ,
  },
  {
    family: "Noto Sans JP",
    load: () =>
      import("@remotion/google-fonts/NotoSansJP") ,
  },
  {
    family: "Noto Sans KR",
    load: () =>
      import("@remotion/google-fonts/NotoSansKR") ,
  },
  {
    family: "Noto Sans Mono",
    load: () =>
      import("@remotion/google-fonts/NotoSansMono") ,
  },
  {
    family: "Noto Sans SC",
    load: () =>
      import("@remotion/google-fonts/NotoSansSC") ,
  },
  {
    family: "Noto Sans TC",
    load: () =>
      import("@remotion/google-fonts/NotoSansTC") ,
  },
  {
    family: "Noto Sans Thai",
    load: () =>
      import("@remotion/google-fonts/NotoSansThai") ,
  },
  {
    family: "Noto Serif",
    load: () =>
      import("@remotion/google-fonts/NotoSerif") ,
  },
  {
    family: "Noto Serif JP",
    load: () =>
      import("@remotion/google-fonts/NotoSerifJP") ,
  },
  {
    family: "Noto Serif KR",
    load: () =>
      import("@remotion/google-fonts/NotoSerifKR") ,
  },
  {
    family: "Noto Serif TC",
    load: () =>
      import("@remotion/google-fonts/NotoSerifTC") ,
  },
  {
    family: "Nunito",
    load: () => import("@remotion/google-fonts/Nunito") ,
  },
  {
    family: "Nunito Sans",
    load: () =>
      import("@remotion/google-fonts/NunitoSans") ,
  },
  {
    family: "Old Standard TT",
    load: () =>
      import("@remotion/google-fonts/OldStandardTT") ,
  },
  {
    family: "Oleo Script",
    load: () =>
      import("@remotion/google-fonts/OleoScript") ,
  },
  {
    family: "Open Sans",
    load: () =>
      import("@remotion/google-fonts/OpenSans") ,
  },
  {
    family: "Orbitron",
    load: () =>
      import("@remotion/google-fonts/Orbitron") ,
  },
  {
    family: "Oswald",
    load: () => import("@remotion/google-fonts/Oswald") ,
  },
  {
    family: "Outfit",
    load: () => import("@remotion/google-fonts/Outfit") ,
  },
  {
    family: "Overpass",
    load: () =>
      import("@remotion/google-fonts/Overpass") ,
  },
  {
    family: "Oxygen",
    load: () => import("@remotion/google-fonts/Oxygen") ,
  },
  {
    family: "PT Sans",
    load: () => import("@remotion/google-fonts/PTSans") ,
  },
  {
    family: "PT Sans Caption",
    load: () =>
      import("@remotion/google-fonts/PTSansCaption") ,
  },
  {
    family: "PT Sans Narrow",
    load: () =>
      import("@remotion/google-fonts/PTSansNarrow") ,
  },
  {
    family: "PT Serif",
    load: () => import("@remotion/google-fonts/PTSerif") ,
  },
  {
    family: "Pacifico",
    load: () =>
      import("@remotion/google-fonts/Pacifico") ,
  },
  {
    family: "Passion One",
    load: () =>
      import("@remotion/google-fonts/PassionOne") ,
  },
  {
    family: "Pathway Gothic One",
    load: () =>
      import("@remotion/google-fonts/PathwayGothicOne") ,
  },
  {
    family: "Patua One",
    load: () =>
      import("@remotion/google-fonts/PatuaOne") ,
  },
  {
    family: "Paytone One",
    load: () =>
      import("@remotion/google-fonts/PaytoneOne") ,
  },
  {
    family: "Permanent Marker",
    load: () =>
      import("@remotion/google-fonts/PermanentMarker") ,
  },
  {
    family: "Philosopher",
    load: () =>
      import("@remotion/google-fonts/Philosopher") ,
  },
  {
    family: "Play",
    load: () => import("@remotion/google-fonts/Play") ,
  },
  {
    family: "Playfair Display",
    load: () =>
      import("@remotion/google-fonts/PlayfairDisplay") ,
  },
  {
    family: "Plus Jakarta Sans",
    load: () =>
      import("@remotion/google-fonts/PlusJakartaSans") ,
  },
  {
    family: "Poppins",
    load: () => import("@remotion/google-fonts/Poppins") ,
  },
  {
    family: "Prata",
    load: () => import("@remotion/google-fonts/Prata") ,
  },
  {
    family: "Prompt",
    load: () => import("@remotion/google-fonts/Prompt") ,
  },
  {
    family: "Public Sans",
    load: () =>
      import("@remotion/google-fonts/PublicSans") ,
  },
  {
    family: "Quattrocento",
    load: () =>
      import("@remotion/google-fonts/Quattrocento") ,
  },
  {
    family: "Quattrocento Sans",
    load: () =>
      import("@remotion/google-fonts/QuattrocentoSans") ,
  },
  {
    family: "Questrial",
    load: () =>
      import("@remotion/google-fonts/Questrial") ,
  },
  {
    family: "Quicksand",
    load: () =>
      import("@remotion/google-fonts/Quicksand") ,
  },
  {
    family: "Rajdhani",
    load: () =>
      import("@remotion/google-fonts/Rajdhani") ,
  },
  {
    family: "Raleway",
    load: () => import("@remotion/google-fonts/Raleway") ,
  },
  {
    family: "Readex Pro",
    load: () =>
      import("@remotion/google-fonts/ReadexPro") ,
  },
  {
    family: "Red Hat Display",
    load: () =>
      import("@remotion/google-fonts/RedHatDisplay") ,
  },
  {
    family: "Righteous",
    load: () =>
      import("@remotion/google-fonts/Righteous") ,
  },
  {
    family: "Roboto",
    load: () => import("@remotion/google-fonts/Roboto") ,
  },
  {
    family: "Roboto Condensed",
    load: () =>
      import("@remotion/google-fonts/RobotoCondensed") ,
  },
  {
    family: "Roboto Flex",
    load: () =>
      import("@remotion/google-fonts/RobotoFlex") ,
  },
  {
    family: "Roboto Mono",
    load: () =>
      import("@remotion/google-fonts/RobotoMono") ,
  },
  {
    family: "Roboto Serif",
    load: () =>
      import("@remotion/google-fonts/RobotoSerif") ,
  },
  {
    family: "Roboto Slab",
    load: () =>
      import("@remotion/google-fonts/RobotoSlab") ,
  },
  {
    family: "Rokkitt",
    load: () => import("@remotion/google-fonts/Rokkitt") ,
  },
  {
    family: "Rowdies",
    load: () => import("@remotion/google-fonts/Rowdies") ,
  },
  {
    family: "Rubik",
    load: () => import("@remotion/google-fonts/Rubik") ,
  },
  {
    family: "Rubik Bubbles",
    load: () =>
      import("@remotion/google-fonts/RubikBubbles") ,
  },
  {
    family: "Rubik Mono One",
    load: () =>
      import("@remotion/google-fonts/RubikMonoOne") ,
  },
  {
    family: "Russo One",
    load: () =>
      import("@remotion/google-fonts/RussoOne") ,
  },
  {
    family: "Sacramento",
    load: () =>
      import("@remotion/google-fonts/Sacramento") ,
  },
  {
    family: "Saira",
    load: () => import("@remotion/google-fonts/Saira") ,
  },
  {
    family: "Saira Condensed",
    load: () =>
      import("@remotion/google-fonts/SairaCondensed") ,
  },
  {
    family: "Sarabun",
    load: () => import("@remotion/google-fonts/Sarabun") ,
  },
  {
    family: "Satisfy",
    load: () => import("@remotion/google-fonts/Satisfy") ,
  },
  {
    family: "Sawarabi Gothic",
    load: () =>
      import("@remotion/google-fonts/SawarabiGothic") ,
  },
  {
    family: "Sawarabi Mincho",
    load: () =>
      import("@remotion/google-fonts/SawarabiMincho") ,
  },
  {
    family: "Sen",
    load: () => import("@remotion/google-fonts/Sen") ,
  },
  {
    family: "Shadows Into Light",
    load: () =>
      import("@remotion/google-fonts/ShadowsIntoLight") ,
  },
  {
    family: "Signika",
    load: () => import("@remotion/google-fonts/Signika") ,
  },
  {
    family: "Signika Negative",
    load: () =>
      import("@remotion/google-fonts/SignikaNegative") ,
  },
  {
    family: "Silkscreen",
    load: () =>
      import("@remotion/google-fonts/Silkscreen") ,
  },
  {
    family: "Six Caps",
    load: () => import("@remotion/google-fonts/SixCaps") ,
  },
  {
    family: "Slabo 27px",
    load: () =>
      import("@remotion/google-fonts/Slabo27px") ,
  },
  {
    family: "Sora",
    load: () => import("@remotion/google-fonts/Sora") ,
  },
  {
    family: "Source Code Pro",
    load: () =>
      import("@remotion/google-fonts/SourceCodePro") ,
  },
  {
    family: "Source Sans 3",
    load: () =>
      import("@remotion/google-fonts/SourceSans3") ,
  },
  {
    family: "Source Serif 4",
    load: () =>
      import("@remotion/google-fonts/SourceSerif4") ,
  },
  {
    family: "Space Grotesk",
    load: () =>
      import("@remotion/google-fonts/SpaceGrotesk") ,
  },
  {
    family: "Space Mono",
    load: () =>
      import("@remotion/google-fonts/SpaceMono") ,
  },
  {
    family: "Special Elite",
    load: () =>
      import("@remotion/google-fonts/SpecialElite") ,
  },
  {
    family: "Spectral",
    load: () =>
      import("@remotion/google-fonts/Spectral") ,
  },
  {
    family: "Tajawal",
    load: () => import("@remotion/google-fonts/Tajawal") ,
  },
  {
    family: "Tangerine",
    load: () =>
      import("@remotion/google-fonts/Tangerine") ,
  },
  {
    family: "Teko",
    load: () => import("@remotion/google-fonts/Teko") ,
  },
  {
    family: "Tinos",
    load: () => import("@remotion/google-fonts/Tinos") ,
  },
  {
    family: "Titan One",
    load: () =>
      import("@remotion/google-fonts/TitanOne") ,
  },
  {
    family: "Titillium Web",
    load: () =>
      import("@remotion/google-fonts/TitilliumWeb") ,
  },
  {
    family: "Ubuntu",
    load: () => import("@remotion/google-fonts/Ubuntu") ,
  },
  {
    family: "Ubuntu Condensed",
    load: () =>
      import("@remotion/google-fonts/UbuntuCondensed") ,
  },
  {
    family: "Ubuntu Mono",
    load: () =>
      import("@remotion/google-fonts/UbuntuMono") ,
  },
  {
    family: "Unbounded",
    load: () =>
      import("@remotion/google-fonts/Unbounded") ,
  },
  {
    family: "Unna",
    load: () => import("@remotion/google-fonts/Unna") ,
  },
  {
    family: "Urbanist",
    load: () =>
      import("@remotion/google-fonts/Urbanist") ,
  },
  {
    family: "Varela Round",
    load: () =>
      import("@remotion/google-fonts/VarelaRound") ,
  },
  {
    family: "Vollkorn",
    load: () =>
      import("@remotion/google-fonts/Vollkorn") ,
  },
  {
    family: "Work Sans",
    load: () =>
      import("@remotion/google-fonts/WorkSans") ,
  },
  {
    family: "Yanone Kaffeesatz",
    load: () =>
      import("@remotion/google-fonts/YanoneKaffeesatz") ,
  },
  {
    family: "Yantramanav",
    load: () =>
      import("@remotion/google-fonts/Yantramanav") ,
  },
  {
    family: "Yellowtail",
    load: () =>
      import("@remotion/google-fonts/Yellowtail") ,
  },
  {
    family: "Yeseva One",
    load: () =>
      import("@remotion/google-fonts/YesevaOne") ,
  },
  {
    family: "Zen Kaku Gothic New",
    load: () =>
      import("@remotion/google-fonts/ZenKakuGothicNew") ,
  },
  {
    family: "Zeyada",
    load: () => import("@remotion/google-fonts/Zeyada") ,
  },
  {
    family: "Zilla Slab",
    load: () =>
      import("@remotion/google-fonts/ZillaSlab") ,
  },
];