
// Check rect r2 is completely inside bounds of r1
// Each rect should be of form {left: , top: , width: , height}
export function rectInRect(r1, r2){
    return ptInRect({x: r2.left, y: r2.top}, r1) ||  
        ptInRect({x: r2.right, y: r2.top}, r1) ||  
        ptInRect({x: r2.right, y: r2.bottom}, r1) ||  
        ptInRect({x: r2.left, y: r2.bottom}, r1);
}

// Check point is inside rect
export function ptInRect(pt, rect){
    if ( (pt.x >= rect.left) && (pt.x <= rect.right) && (pt.y >= rect.top) && (pt.y <= rect.bottom)){ 
    return true;
  } 
  return false;
}