import React, { useState, useEffect, useRef } from 'react';
import { useEditorContext } from '../../context/editor/editorcontext';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group } from 'react-konva';

function Screen () {

    const editorContext = useEditorContext();

    const onClickFn = (e) => {
        // change screen active state
        editorContext.changeScreenActiveState(e);
    }
      
    return(
        <Layer>
              {editorContext.screens ? editorContext.screens.map((screen) => (
                <>
                {screen.isVisible ? 
                    <Group name='screen' id={screen.id} x={0} y={0} width={screen.width}>
                        <Rect
                            key={screen.id}
                            x={screen.xpos}
                            y={screen.ypos} 
                            width={screen.width}
                            height={screen.height}
                            fill={screen.fill}
                            onClick={(e)=>onClickFn(screen)}
                            stroke={screen.isActive ? '#0090FF' : null}
                            strokeWidth={4}
                            strokeEnabled={true}
                        />
                        <KonvaText 
                            x={screen.xpos}
                            y={screen.ypos - 35} 
                            fontSize={24} 
                            text={screen.name}
                            fill={'#838CD1'}
                        />        
                    </Group>

                    : null
                }
                </>  
              )):null}
        </Layer>
    )
}

export default Screen;