
export const textfieldProtoOptions = {
    trigger:['On enter', 'On click'],
    interaction:['Navigate to screen']
}

export const buttonProtoOptions = {
    trigger:['On click'],
    interaction:['Navigate to screen']
}

export const textProtoOptions = {
    trigger:['On click'],
    interaction:['Navigate to screen']
}

export const switchProtoOptions = {
    trigger:['Switch on', 'Switch off'],
    interaction:['Navigate to screen']
}

export const imageProtoOptions = {
    trigger:['On click'],
    interaction:['Navigate to screen']
}

export const containerProtoOptions = {
    trigger:['On click'],
    interaction:['Navigate to screen']
}