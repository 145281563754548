import React, {useRef, useEffect, useLayoutEffect, useState, useMemo, memo} from "react";
import { Html } from 'react-konva-utils';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { usePrototypeContext } from "../../../../context/prototype/prototypecontext";
import { prototypeSwitcher } from "../../utils/prototypeGenerator";
import { useMeasure } from "react-use";
import { Spring, animated, useSpring, useSpringValue } from '@react-spring/konva';
import CorePlayerTextField from "./core/coreplayertextfield";

function PlayerTextField({element, screenWidth, screenHeight, canvasX, canvasY}) {

    const prototypeContext = usePrototypeContext(); 

    console.log(element)
 
    return(
        <>
            {
                element.in_screen &&

                <CorePlayerTextField
                    key={element.id}
                    element={element}
                    screenWidth={screenWidth} 
                    screenHeight={screenHeight} 
                    canvasX={canvasX} 
                    canvasY={canvasY}
                />
            }
       </>
    )
}

export default memo(PlayerTextField);