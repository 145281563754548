import React, {useRef, useEffect, useLayoutEffect, useState, memo} from "react";
import { Html } from 'react-konva-utils';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { usePrototypeContext } from "../../../../../context/prototype/prototypecontext";
import { prototypeSwitcher } from "../../../utils/prototypeGenerator";
import { useMeasure } from "react-use";
import { RepositionElements } from "../../../utils/repositionElements";
import { Spring, animated, useSpring, useSpringValue} from '@react-spring/konva';
import Konva from "konva";
import executeGlobalAnim from "../../../utils/executeGlobalAnim";

function CorePlayerSwitch({key, element, screenWidth, screenHeight, canvasX, canvasY}) {

   
    const prototypeContext = usePrototypeContext();
    const [domDimensions, setDomDimensions] = useState({width:50, height:50});
    const [dimensionRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    const [activateElement, setActivateElement] = useState(false);
    const [value, setValue] = useState(element.value)
    const inputRef = React.useRef();

    const [translatedX, setTranslatedX] = useState(0);
    const [translatedY, setTranslatedY] = useState(0);

    const [elementX, setElementX] = useState(0);
    const [elementY, setElementY] = useState(0);

    const [switcher, setSwitcher] = useState(false);
    
    const animRef = useRef();

    const runAnim = (xval, animTrigger, anim) => {
        new Konva.Tween({
            node: animRef.current,
            easing: Konva.Easings.Linear,
            duration: 0.2,
            x: xval,
            onFinish: () => {
                setElementX(xval);
                if(animTrigger.exitScreen == element.screen && anim == 'TransitionOutLeftRight'){
                    setElementX(translatedX + element.xpos + prototypeContext.screens[0].width)
                }
            }
        }).play();
    }

    useEffect(()=>{
            //console.log(element);
            const elementScreen = prototypeContext.screens.find((i) => i.id == element.screen);
            const screen1 = {xpos: elementScreen?.xpos, ypos: elementScreen?.ypos} 
            const screen2 = {xpos: canvasX - screenWidth/2, ypos: canvasY - screenHeight/2}
            //console.log(screen1, screen2)
            const xy = RepositionElements(screen1, screen2);

            setTranslatedX(xy.xpos);
            setTranslatedY(xy.ypos);
            setElementX(element.screen == prototypeContext.currentRenderedScreen.id ? xy.xpos + element.xpos : xy.xpos + element.xpos + prototypeContext.screens[0].width );
            setElementY(xy.ypos + element.ypos);
            //console.log(xy, xy.ypos, element.ypos);
    },[])

    useEffect(()=>{
        setDomDimensions({
            width: width,
            height: height
        });
    },[width, height])

    useEffect(()=>{
        //wait for animation trigger
        // anim trigger
        if( prototypeContext.animTrigger.type == 'global' && prototypeContext.animTrigger.exitScreen == element.screen)
        {
            //animate all elements that are in the current screen
            const globalAnim = executeGlobalAnim(prototypeContext.animTrigger.exitScreenAnimation, element, translatedX, prototypeContext.animTrigger);
            runAnim(globalAnim, prototypeContext.animTrigger, prototypeContext.animTrigger.exitScreenAnimation);
            setActivateElement(false);

        }else if (prototypeContext.animTrigger.type == 'global' && prototypeContext.animTrigger.entranceScreen == element.screen)
        {
            const globalAnim = executeGlobalAnim(prototypeContext.animTrigger.entranceScreenAnimation, element, translatedX, prototypeContext.animTrigger);
            runAnim(globalAnim, prototypeContext.animTrigger, prototypeContext.animTrigger.entranceScreenAnimation);

            setActivateElement(false);
            // stop state being called multiple times
            if(prototypeContext.currentRenderedScreen.id != element.screen){
                const screen = prototypeContext.screens.filter((i)=>i.id == element.screen);
                prototypeContext.setCurrentScreen(screen[0]);
                prototypeContext.setCurrentRenderedElements(element);
            }
            
        }else if(prototypeContext.animTrigger.type == 'individual' && prototypeContext.animTrigger.originElementId == element.id){
            // animate only this element
            console.log('button individual', element.id);
        }
    },[prototypeContext.animTrigger])


    const handlePrototype = (event, element) => {
        //setActivateElement(true)
        console.log(event);
        const res = prototypeContext.triggerPrototype(event, element);
        //console.log('calling handlePrototype', event, res);
        if(res == false && event == 'Switch on' || 'Switch off'){
            // for 'on enter' to work On click needs
            // to make text field editable
            setActivateElement(true);
            //inputRef.current.focus();
        }else if(res == true){
            setActivateElement(true);
        }else{
            setActivateElement(false);
            //inputRef.current.blur();
        }
    }

    return(
        <>
            {
                <Group 
                    key={key}
                    ref={animRef}
                    x={element.grouped ? element.xpos : elementX}
                    y={element.grouped ? element.ypos : elementY}
                    width={element.width}
                    height={element.height}
                >
                    <Html divProps={{ style:{pointerEvents: activateElement ? 'auto' : 'none'}}}>
                        <div ref={dimensionRef}>
                            <div 
                                style={{
                                    width: element.width, 
                                    height: element.height, 
                                    transition: 'ease-in-out',
                                    transitionDuration: '.3s',
                                    backgroundColor: switcher ? element.active_colour : element.disabled_colour,
                                    padding: 10,
                                    borderTopLeftRadius: element.border_radius_tl,
                                    borderTopRightRadius: element.border_radius_tr,
                                    borderBottomLeftRadius: element.border_radius_bl,
                                    borderBottomRightRadius: element.border_radius_br,
                                    boxSizing: 'border-box'
                                }} 
                                onClick={()=>{
                                    //console.log('clicking in  switch...');
                                    if(switcher){
                                        //console.log('clicking off');
                                        setSwitcher(false);
                                        setTimeout(()=>{handlePrototype('Switch off', element);},500)
                                        
                                    }else{
                                        //console.log('clicking on');
                                        setSwitcher(true);
                                        setTimeout(()=>{handlePrototype('Switch on', element);},500)
                                    }
                                }}
                            > 
                                <div style={{
                                    width: parseFloat(element.height), 
                                    height: '100%', 
                                    backgroundColor: element.notch_colour,
                                    transition: 'ease-in-out',
                                    transitionDuration: '.3s',
                                    borderRadius: 100,
                                    transform: switcher ? `translateX(${domDimensions.width - parseFloat(element.height) - 20}px)` :`translateX(${0}px)` ,
                                }}/>
                            </div>
                        </div>
                    </Html>
                    <Rect
                        onClick={()=>{
                            //console.log('clicking...');
                            if(switcher){
                                //console.log('clicking off');
                                setSwitcher(false);
                                setTimeout(()=>{handlePrototype('Switch off', element);},500)
                            }else{
                                //console.log('clicking on');
                                setSwitcher(true);
                                setTimeout(()=>{handlePrototype('Switch on', element);},500)
                            }
                        }}
                        width={domDimensions.width}
                        height={domDimensions.height}
                        fill={'transparent'}
                    />
                </Group>  

            }
        </>
    )
}

export default memo(CorePlayerSwitch);
