import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth/authcontext";

function RequireAuth({ session, children }) {
    // const { authed } = useAuth();
    const authContext = useAuthContext();

   // console.log('in require auth', session)
  
    return session ? children : <Navigate to="/login" replace />;
}

export default RequireAuth;