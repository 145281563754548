// const getSnapLines = (stageRef, excludedShape) => {
//     const stage = stageRef;
//     if (!stage) return;

//     //console.log('stage', stage)
  
//     const vertical = [];
//     const horizontal = [];
  
//     // We snap over edges and center of each object on the canvas
//     // We can query and get all the shapes by their name property `shape`.
//     stage.find(".shape").forEach(shape => {
//       // We don't want to snap to the selected shape, so we will be passing them as `excludedShape`
//       if (shape === excludedShape) return;
  
//       const box = shape.getClientRect({ relativeTo: stage });
//       console.log('rect box', box);
//       vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
//       horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
//     });
  
//     return {
//       vertical: vertical.flat(),
//       horizontal: horizontal.flat(),
//     };
// };


// const getShapeSnappingEdges = (stageRef, transformerRef) => {
//     const stage = stageRef;
//     const tr = transformerRef.current;
  
//     const box = tr.findOne(".back").getClientRect({ relativeTo: stage });
//     const absPos = tr.findOne(".back").absolutePosition();

//     console.log('box', box);
//     console.log('absPos', absPos);

  
//     return {
//       vertical: [
//         // Left vertical edge
//         {
//           guide: box.x,
//           offset: absPos.x - box.x,
//           snap: "start",
//         },
//         // Center vertical edge
//         {
//           guide: box.x + box.width / 2,
//           offset: absPos.x - box.x - box.width / 2,
//           snap: "center",
//         },
//         // Right vertical edge
//         {
//           guide: box.x + box.width,
//           offset: absPos.x - box.x - box.width,
//           snap: "end",
//         },
//       ],
//       horizontal: [
//         // Top horizontal edge
//         {
//           guide: box.y,
//           offset: absPos.y - box.y,
//           snap: "start",
//         },
//         // Center horizontal edge
//         {
//           guide: box.y + box.height / 2,
//           offset: absPos.y - box.y - box.height / 2,
//           snap: "center",
//         },
//         // Bottom horizontal edge
//         {
//           guide: box.y + box.height,
//           offset: absPos.y - box.y - box.height,
//           snap: "end",
//         },
//       ],
//     };
//   };
  
//   const SNAP_THRESHOLD = 5;
//   const getClosestSnapLines = (possibleSnapLines, shapeSnappingEdges) => {

//     const getAllSnapLines = direction => {
//       const result = [];
//       console.log('possibleSnapLines', possibleSnapLines);
//       console.log('shapeSnappingEdges', shapeSnappingEdges);
//       possibleSnapLines[direction].forEach(snapLine => {
//         shapeSnappingEdges[direction].forEach(snappingEdge => {
//             //console.log('snapLine', snapLine);
//             //console.log('snappingEdge', snappingEdge);
//           const diff = Math.abs(snapLine - snappingEdge.guide);
//           console.log('diff', direction,  diff)
//           // If the distance between the line and the shape is less than the threshold, we will consider it a snapping point.
//           if (diff > SNAP_THRESHOLD) return;
  
//           const { snap, offset } = snappingEdge;
//           result.push({ snapLine, diff, snap, offset });
//         });
//       });
//       return result;
//     };
  
//     const resultV = getAllSnapLines("vertical");
//     const resultH = getAllSnapLines("horizontal");

//     console.log('resultV', resultV);
//     console.log('resultH', resultH);
  
//     const closestSnapLines = [];
  
//     const getSnapLine = ({ snapLine, offset, snap }, orientation) => {
//       return { snapLine, offset, orientation, snap };
//     };
  
//     // find closest vertical and horizontal snappping lines
//     const [minV] = resultV.sort((a, b) => a.diff - b.diff);
//     const [minH] = resultH.sort((a, b) => a.diff - b.diff);
//     if (minV) closestSnapLines.push(getSnapLine(minV, "V"));
//     if (minH) closestSnapLines.push(getSnapLine(minH, "H"));
  
//     return closestSnapLines;
//   };

  
//   const drawLines = (lines = [], setHLines, setVLines) => {
//     if (lines.length > 0) {
//       const lineStyle = {
//         stroke: "rgb(0, 161, 255)",
//         strokeWidth: 1,
//         name: "guid-line",
//         dash: [4, 6],
//       };
//       const hLines = [];
//       const vLines = [];
//       lines.forEach(l => {
//         if (l.orientation === "H") {
//           const line = {
//             points: [-6000, 0, 6000, 0],
//             x: 0,
//             y: l.snapLine,
//             ...lineStyle,
//           };
//           hLines.push(line);
//         } else if (l.orientation === "V") {
//           const line = {
//             points: [0, -6000, 0, 6000],
//             x: l.snapLine,
//             y: 0,
//             ...lineStyle,
//           };
//           vLines.push(line);
//         }
//       });
  
//       // Set state
//       setHLines(hLines);
//       setVLines(vLines);
//     }
//   };

  
//   export const onDragMoveOS = (stageRef, transformerRef, setHLines, setVLines) => {
//     const target = transformerRef.current;

//     if(!target) return;
//     //console.log('transformerRef', target)
//     const [selectedNode] = target.getNodes();
//     //console.log('selectedNode', selectedNode)

//     if (!selectedNode) return;
  
//     const possibleSnappingLines = getSnapLines(stageRef, selectedNode);
//     const selectedShapeSnappingEdges = getShapeSnappingEdges(stageRef, transformerRef);

//     console.log('possible snapping', possibleSnappingLines);
//     //console.log('selectedShapeSnappingEdges', selectedShapeSnappingEdges);
  
//     const closestSnapLines = getClosestSnapLines(
//       possibleSnappingLines,
//       selectedShapeSnappingEdges
//     );

//     console.log('closest snap lines', closestSnapLines)
  
//     // Do nothing if no snapping lines
//     if (closestSnapLines.length === 0) {
//       //console.log('no lines')
//       setHLines([]);
//       setVLines([]);
  
//       return;
//     }
  
//     console.log('drawing lines')
//     // draw the lines
//     drawLines(closestSnapLines, setHLines, setVLines);
  
//     const orgAbsPos = target.absolutePosition();
//     const absPos = target.absolutePosition();
  
//     // Find new position
//     closestSnapLines.forEach(l => {
//       const position = l.snapLine + l.offset;
//       if (l.orientation === "V") {
//         absPos.x = position;
//       } else if (l.orientation === "H") {
//         absPos.y = position;
//       }
//     });
  
//     // calculate the difference between original and new position
//     const vecDiff = {
//       x: orgAbsPos.x - absPos.x,
//       y: orgAbsPos.y - absPos.y,
//     };
  
//     // apply the difference to the selected shape.
//     const nodeAbsPos = selectedNode.getAbsolutePosition();
//     const newPos = {
//       x: nodeAbsPos.x - vecDiff.x,
//       y: nodeAbsPos.y - vecDiff.y,
//     };
  
//     selectedNode.setAbsolutePosition(newPos);
//   };

const SNAP_THRESHOLD = 1;

export const onDragMoveOS = (stageRef, selectedShape) => {
  if (!stageRef || !selectedShape) return [];

  // Get the selected shape's rect from children
  const selectedRect = selectedShape.children.find(child => 
    child.attrs && child.attrs.name === 'shape'
  );
  
  // Get other shapes and the screen
  const otherShapes = stageRef.find('.shape').filter(shape => shape !== selectedRect);
  const screen = stageRef.find('Group').find(group => group.attrs.name === 'screen');
  
  const stage = stageRef;
  const stageScale = stage.scaleX();
  const stagePos = stage.position();
  
  const getAbsolutePosition = (shape) => {
    const pos = shape.absolutePosition();
    return {
      x: (pos.x - stagePos.x) / stageScale,
      y: (pos.y - stagePos.y) / stageScale
    };
  };

  const selectedAbsPos = getAbsolutePosition(selectedShape);
  const selectedWidth = selectedShape.width();
  const selectedHeight = selectedShape.height();
  
  const selectedCenterX = selectedAbsPos.x + (selectedWidth / 2);
  const selectedCenterY = selectedAbsPos.y + (selectedHeight / 2);

  let snapLines = [];

  // Shape snapping logic
  otherShapes.forEach(otherShape => {
    if (otherShape === selectedRect) return;

    const otherPos = getAbsolutePosition(otherShape);
    const otherWidth = otherShape.width();
    const otherHeight = otherShape.height();
    
    const otherCenterX = otherPos.x + (otherWidth / 2);
    const otherCenterY = otherPos.y + (otherHeight / 2);

    const leftDiff = Math.abs(selectedAbsPos.x - otherPos.x);
    const rightDiff = Math.abs((selectedAbsPos.x + selectedWidth) - (otherPos.x + otherWidth));
    const topDiff = Math.abs(selectedAbsPos.y - otherPos.y);
    const bottomDiff = Math.abs((selectedAbsPos.y + selectedHeight) - (otherPos.y + otherHeight));
    const horizontalCenterDiff = Math.abs(selectedCenterY - otherCenterY);
    const verticalCenterDiff = Math.abs(selectedCenterX - otherCenterX);

    if (leftDiff < SNAP_THRESHOLD) {
      snapLines.push({
        type: 'vertical',
        x: otherPos.x,
        y: 0
      });
    }

    if (rightDiff < SNAP_THRESHOLD) {
      snapLines.push({
        type: 'vertical',
        x: otherPos.x + otherWidth,
        y: 0
      });
    }

    if (topDiff < SNAP_THRESHOLD) {
      snapLines.push({
        type: 'horizontal',
        x: 0,
        y: otherPos.y
      });
    }

    if (bottomDiff < SNAP_THRESHOLD) {
      snapLines.push({
        type: 'horizontal',
        x: 0,
        y: otherPos.y + otherHeight
      });
    }

    if (horizontalCenterDiff < SNAP_THRESHOLD) {
      snapLines.push({
        type: 'horizontal',
        x: 0,
        y: otherCenterY
      });
    }

    if (verticalCenterDiff < SNAP_THRESHOLD) {
      snapLines.push({
        type: 'vertical',
        x: otherCenterX,
        y: 0
      });
    }
  });

  if (screen) {
    // Get the screen's rect directly
    const screenRect = screen.findOne('Rect');
    if (!screenRect) return snapLines;

    // Get the rect's position and dimensions
    const screenRectPos = getAbsolutePosition(screenRect);
    const screenWidth = screenRect.width();
    const screenHeight = screenRect.height();
    
    const screenCenterX = screenRectPos.x + (screenWidth / 2);
    const screenCenterY = screenRectPos.y + (screenHeight / 2);

    // console.log('Screen rect position:', screenRectPos);
    // console.log('Screen rect dimensions:', { width: screenWidth, height: screenHeight });
    // console.log('Screen center:', { x: screenCenterX, y: screenCenterY });
    // console.log('Selected center:', { x: selectedCenterX, y: selectedCenterY });

    if (Math.abs(selectedCenterX - screenCenterX) < SNAP_THRESHOLD) {
      snapLines.push({
        type: 'vertical',
        x: screenCenterX,
        y: 0
      });
    }

    if (Math.abs(selectedCenterY - screenCenterY) < SNAP_THRESHOLD) {
      snapLines.push({
        type: 'horizontal',
        x: 0,
        y: screenCenterY
      });
    }
  }

  return snapLines;
};