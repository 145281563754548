import React, { useEffect, useState } from "react"
import * as Popover from '@radix-ui/react-popover';
import './textpanel.css'
import { useEditorContext } from "../../../../../../context/editor/editorcontext";
import FontPicker from "../../fontpicker/fontpicker";
import FontSize from "../../fontpicker/fontsize/fontsize";
import FontWeight from "../../fontpicker/fontweight/fontweight";
import { HexColorPicker, HexColorInput } from "react-colorful";
import '../../elementpanels/elementpanel.css';
import { TextAlignLeftIcon, TextAlignCenterIcon, TextAlignRightIcon } from "@radix-ui/react-icons";

function TextPanel ({item}){

    const editorContext = useEditorContext();
    const [value, setValue] = useState(item.value);
    const [font, setFont] = useState(item.font);
    const [fontWeight, setFontWeight] = useState(item.font_weight);
    const [fontSize, setFontSize] = useState(item.font_size);
    const [fontColour, setFontColour] = useState(item.font_colour);
    const [lineHeight, setLineHeight] = useState(item.line_height);
    const [letterSpacing, setLetterSpacing] = useState(item.letter_spacing);
    const [textAlign, setTextAlign] = useState(item.text_alignment);

    useEffect(()=>{
     setValue(item.value);
     setFont(item.font);
     setFontWeight(item.font_weight);
     setFontSize(item.font_size);
     setFontColour(item.font_colour);
     setLineHeight(item.line_height);
     setLetterSpacing(item.letter_spacing);
     setTextAlign(item.text_alignment);
    },[item])

    const updateElement = (value, font, fontWeight, fontSize, fontColour, lineHeight, letterSpacing, textAlign) => { 
        if(item?.id == editorContext.activeElement?.id){
            const props = { 
                type: item.type,
                id: item.id,
                grouped: item.grouped,
                grouped_by: item.grouped_by,
                value: value,
                font: font,
                font_size: fontSize,
                font_weight: fontWeight,
                font_colour: fontColour,
                line_height: lineHeight,
                letter_spacing: letterSpacing,
                text_alignment: textAlign,
            }
            console.log(props)
            editorContext.updateElementCustomPanel(props);
        }
    }

    const handlePanel = (value, font, fontWeight, fontSize, fontColour, lineHeight, letterSpacing, textAlign) => {
        setValue(value);
        setFont(font);
        setFontWeight(fontWeight);
        setFontSize(fontSize);
        setFontColour(fontColour);
        setLineHeight(lineHeight);
        setLetterSpacing(letterSpacing);
        setTextAlign(textAlign);
        //console.log(parseInt(lineHeight))
        updateElement(value, font, fontWeight, fontSize, fontColour, lineHeight, letterSpacing, textAlign);
    }

    return(
        <div className="element-panel-container">
            <div className="element-panel-title">
                <p>Text</p>
            </div>
            

            <div className="element-panel-section">
                <div className="element-panel-input">
                    <p>Title</p>
                    <input value={value} onChange={(e)=>handlePanel(e.target.value, font, fontWeight, fontSize, fontColour, lineHeight, letterSpacing, textAlign)}/>
                </div>
            </div>

            <div className="element-panel-section">
                <div>
                    <FontPicker item={item} setFont={setFont} onChange={(e)=>handlePanel(value, e, fontWeight, fontSize, fontColour, lineHeight, letterSpacing, textAlign)}/>
                </div>
                <div>
                    <FontWeight item={item} setFontWeight={setFontWeight} font={font} onChange={(e)=>handlePanel(value, font, e, fontSize, fontColour, lineHeight, letterSpacing, textAlign)}/>
                </div>
                <div>
                    <FontSize item={item} setFontSize={setFontSize} onChange={(e)=>handlePanel(value, font, fontWeight, e, fontColour, lineHeight, letterSpacing, textAlign)}/>
                </div>
                    <div>
                        <Popover.Root>
                            <Popover.Trigger asChild>
                                <div className="element-panel-colour">
                                    <p>Font colour</p>
                                    <div>
                                        <div style={{width: 24, height: 24, background: fontColour}}/>
                                        <p>{fontColour}</p>
                                    </div>
                                </div>
                            </Popover.Trigger>
                            <Popover.Portal>
                            <Popover.Content className="PopoverContent" sideOffset={10}>
                                <div className="element-panel-colour-picker">
                                    <HexColorPicker color={fontColour} onChange={(e)=>handlePanel(value, font, fontWeight, fontSize, e, lineHeight, letterSpacing, textAlign)} />
                                    <HexColorInput color={fontColour} onChange={(e)=>handlePanel(value, font, fontWeight, fontSize, e, lineHeight, letterSpacing, textAlign)} />
                                </div>
                            </Popover.Content>
                            </Popover.Portal>
                        </Popover.Root>
                    </div>
            </div>
            
           
                <div className="element-panel-input">
                    <p>Line height</p>
                    <input type="number" value={lineHeight} onChange={(e)=>handlePanel(value, font, fontWeight, fontSize, fontColour, e.target.value, letterSpacing, textAlign)} />
                </div>

                <div className="element-panel-input">
                    <p>Letter spacing</p>
                    <input type="number" value={letterSpacing} onChange={(e)=>handlePanel(value, font, fontWeight, fontSize, fontColour, lineHeight, e.target.value, textAlign)} />
                </div>

                <div className="element-panel-tabs">
                    <p>Text alignment</p>
                    <div>
                        <button className={textAlign == 'left' ? "element-panel-tabs-selected" : null } onClick={()=>handlePanel(value, font, fontWeight, fontSize, fontColour, lineHeight, letterSpacing, 'left')}>
                            <TextAlignLeftIcon width={15}/>
                        </button>
                        <button className={textAlign == 'center' ? "element-panel-tabs-selected" : null }  onClick={()=>handlePanel(value, font, fontWeight, fontSize, fontColour, lineHeight, letterSpacing, 'center')}>
                            <TextAlignCenterIcon width={15}/>
                        </button>
                        <button className={textAlign == 'right' ? "element-panel-tabs-selected" : null }  onClick={()=>handlePanel(value, font, fontWeight, fontSize, fontColour, lineHeight, letterSpacing, 'right')}>
                            <TextAlignRightIcon width={15}/>
                        </button>
                    </div>
                </div>
        </div>
    )
}

export default TextPanel