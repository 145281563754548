import React, { useState, useEffect, useRef, Fragment } from 'react';
import { supabase } from '../../../supabase/supabaseclient';
import { useParams } from 'react-router-dom';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthContext } from '../../../context/auth/authcontext';
import './inviteMemberPassword.css'

function InviteMemberPassword(){

    const navigate = useNavigate();
    const authContext = useAuthContext();
    const { email, id } = useParams();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [errorState, setErrorState] = useState('');

    async function signUpNewUser (TeamId) {

        if(!firstName || !lastName || !password){
            setErrorState('Please fill out all fields');
            return;
        }
        await supabase.auth.signUp({
          email: email,
          password: password,
        }).then((data)=>{
            // console.log(data);
            // console.log(data.data.user.id)
            if(!data.data.user){
                setErrorState(data.error.message);
                return;
            }
            supabase
            .from('Users')
            .insert({
                auth_id: data.data.user.id,
                first_name: firstName,
                last_name: lastName,
                email: email
            })
            .select()
            .then(async(res)=>{
                // save user us
                console.log(res.data[0]);
                authContext.setUserFn(res.data[0]);
               // navigate('/signup/your-team');
                await supabase
                .from('Members')
                .insert({
                    member_id: res.data[0].id,
                    team_id: TeamId
                }).then(async()=>{
                    await supabase.from('Invites').delete().eq('team_id', id).eq('email', email);
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 2000);
                    
                })
            })
        });
    }


    return (
        <div className='signup-main'>
            <div className='signup-header-container'>
                <h1 className="signup-header">Rivveo <span>BETA</span></h1>
            </div>
            <div className='signup-box'>
                
                <div className='Invite-Member-Password-header-container'>
                    <h1 className='signup-box-header'>Create your account</h1>
                </div>

                <div className='Invite-Member-Password-Email'>
                    <p>{email}</p>
                </div>

                <div className='signup-box-input'>
                    <label>First name</label>
                    <input type="text" id="firstname" value={firstName} placeholder="Your First Name" onChange={(e) => setFirstName(e.target.value)}/>
                </div>
                <div className='signup-box-input'>
                    <label>Last name</label>
                    <input type="text" id="lastname" value={lastName} placeholder="Your Last Name" onChange={(e) => setLastName(e.target.value)}/>
                </div>
                <div className='signup-box-input'>
                    <label>Password</label>
                    <input type="password" id="password" value={password} placeholder="Your Password" onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <p className='signup-error'>{errorState}</p>
                <button className='signup-box-button' onClick={()=>signUpNewUser(id)}>
                    Create Account
                </button>
            </div>
            
        </div>

    )

}

export default InviteMemberPassword;