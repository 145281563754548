import React, { useEffect, useState } from "react"
import * as Popover from '@radix-ui/react-popover';
import './imagepanel.css'
import { useEditorContext } from "../../../../../../context/editor/editorcontext";
import { supabase } from "../../../../../../supabase/supabaseclient";
import { useAuthContext } from "../../../../../../context/auth/authcontext";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import '../../elementpanels/elementpanel.css';
import { ChevronDownIcon, UploadIcon } from "@radix-ui/react-icons";

function ImagePanel ({item, fileId}){

    const editorContext = useEditorContext();
    const [url, setUrl] = useState(item.url);
    const [imageName, setImageName] = useState(item.image_name);
    const [ImageMode, setImageMode] = useState(item.aspect_ratio);

    useEffect(()=>{
      setUrl(item.url);
      setImageName(item.image_name);
      setImageMode(item.aspect_ratio);
      console.log(item.aspect_ratio);
    },[item])

    const updateElement = (url, imageName, imageMode) => { 
        if(item?.id == editorContext.activeElement?.id){
            const props = { 
                type: item.type,
                id: item.id,
                grouped: item.grouped,
                grouped_by: item.grouped_by,
                url: url,
                image_name: imageName,
                aspect_ratio: imageMode,
            }
            editorContext.updateElementCustomPanel(props);
        }
    }

    const handlePanel = (url, imageName, imageMode) => {
        setUrl(url);
        setImageName(imageName);
        setImageMode(imageMode);
        updateElement(url, imageName, imageMode);
    }

    function getFileNameWithExt(event) {

        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
          return;
        }
      
        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
      
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        return `${item.id}.${ext}`  
    }

    function getFileName(event) {

        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
          return;
        }
      
        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        return `${fileName}.${ext}`  
    }

    const uploadImage = async(event) => {
        const { data, error } = await supabase.storage
        .from('Rivveo_File_Images')
        .upload( `images/${fileId}/${getFileNameWithExt(event)}`, event.target.files[0],{
            upsert: true,
        });

        if(data){
            console.log(data);
            console.log('image mdoe', ImageMode);
            handlePanel(data.path, getFileName(event), ImageMode);
        }

        if(error){
            console.log(error);
        }
    }

    return(
        <div className="element-panel-container">
            <div className="element-panel-title">
                <p>Image</p>
            </div>
            <div className="element-panel-upload">
                <p>Add Image</p>
                <p>{item.image_name}</p>
                <button onClick={()=>document.getElementById('getFile').click()}>
                    <UploadIcon color="#777FBD" width={16} height={16}/>
                    {url ? 'Change Image':'Upload Image'} 
                </button>
                <input
                    type={"file"}
                    id={'getFile'}
                    name={"myImage"}
                    style={{visibility: 'hidden'}}
                    onChange={(event) => {
                        //console.log(event);
                        uploadImage(event);
                    }}
                />
            </div>
            <div>
                
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                        <div className="element-panel-dropdown-trigger">
                            <p>Image Mode</p>
                            <div>
                                <button aria-label="Customise options">
                                    {ImageMode}
                                    <ChevronDownIcon color="#777FBD" width={16} height={16}/>
                                </button>
                            </div>
                        </div>
                        
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                        <DropdownMenu.Content className="element-panel-dropdown-content">
                            <DropdownMenu.Item className="DropdownMenuItem" onClick={()=>{handlePanel(url, imageName, 'fill')}}>
                                Fill
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className="DropdownMenuItem" onClick={()=>{handlePanel(url, imageName, 'contain')}}>
                                Contain
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className="DropdownMenuItem" onClick={()=>{handlePanel(url, imageName, 'cover')}}>
                                Cover
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className="DropdownMenuItem" onClick={()=>{handlePanel(url, imageName, 'none')}}>
                                None
                            </DropdownMenu.Item>
                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            </div>
        </div>
    )
}

export default ImagePanel