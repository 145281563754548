import React, {useEffect, useState} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { PlusIcon, MobileIcon, ChevronDownIcon } from '@radix-ui/react-icons';
import './screenSelector.css'
import { useEditorContext } from '../../../../context/editor/editorcontext';


function ScreenSelector ({setSelectedScreen}){

    const editorContext = useEditorContext()
    const [selection, setSelection] = useState('set screen');
    const [selectedId, setSelectedId] = useState(null);

    const screenOnClick = (screen) => { 
        // set screen by id as isVisible
        const oldProps = {
            id: selectedId,
            isVisible: false
        }
        const props = {
            id: screen.id,
            isVisible: true
        }

        //console.log('this is the screen', screen)
        editorContext.setScreenVisible(props, oldProps);
        setSelection(screen.name);
        setSelectedScreen(screen.id);
        setSelectedId(screen.id);
    }

    useEffect(()=>{

        if(editorContext.screens){
            // get screen visible
        
            const vis = editorContext.screens.find(e => e.isVisible === true);
            //console.log('is screen visible', vis)
            if(vis){
                //console.log('screen is visible', vis)
                setSelection(vis.name);
                setSelectedScreen(vis.id);
                setSelectedId(vis.id);
            }else{
                //setting first screen visible if none are currently set
                const props = {
                    id: editorContext.screens[0].id,
                    isVisible: true
                }
                editorContext.setScreenVisible(props);
                setSelection(editorContext.screens[0].name);
                setSelectedScreen(editorContext.screens[0].id);
                setSelectedId(editorContext.screens[0].id);
            }
        }
        

    },[editorContext.screens])

    return(
        <div className='triggerContainer'>
            <DropdownMenu.Root className="screen-selector-container">
                <div>
                    <div className='screen-selector-add-screen'>
                        <button onClick={()=>editorContext.addScreen(editorContext.currentFileScreenSize)}>
                            add screen <PlusIcon/>
                        </button>
                    </div>    
                    <DropdownMenu.Trigger className='screen-selector-selection' asChild>
                        <button className="screen-selector-selection-button" aria-label="Customise options">
                            <span>
                                <MobileIcon color='#9B90F2'/>
                                <p>{selection}</p>
                            </span>
                            <ChevronDownIcon width={20} height={20} color='#9B90F2'/>
                        </button>
                    </DropdownMenu.Trigger>
                </div>
                
                <DropdownMenu.Portal>
                    <DropdownMenu.Content className="screen-selector-content">
                        {
                            editorContext.screens.map((screen, i)=>(
                                <DropdownMenu.Item className="screen-selector-item" onClick={()=>screenOnClick(screen)}>
                                    {screen.name}
                                </DropdownMenu.Item>
                            ))
                        }
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </div>
        
    )
}

export default ScreenSelector;